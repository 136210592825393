<template>
  <div>
    <card-table title="房间列表" opBtn="新增房间" :columns="columns" :tableData="data" @edit="visible = true">
      <template slot="table-btn">
        <el-table-column fixed="right" label="操作" width="240" align="center">
          <template slot-scope="scope">
            <table-button type="primary" @click="editItem(scope.row)">编辑</table-button>
            <table-button type="info" @click="addDevice(scope.row)">添加设备</table-button>
            <table-button type="danger" @click="delItem(scope.row.id)">删除</table-button>
          </template>
        </el-table-column>
      </template>
    </card-table>
    <el-dialog
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :title="title"
      :visible.sync="visible"
      width="540px"
      :before-close="handleClose"
    >
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px">
        <el-form-item label="房间名称" prop="name">
          <el-input v-model="ruleForm.name" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="房间面积" prop="area">
          <el-input v-model="ruleForm.area" placeholder="请输入">
            <template slot="append">m²</template>
          </el-input>
        </el-form-item>
        <el-form-item label="房间类型" prop="type">
          <el-select v-model="ruleForm.type" placeholder="请选择" clearable :disabled="title == '编辑户型'">
            <el-option
              v-for="item in typeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="$route.query.houseTemplateType != 1" label="楼层选择" prop="floor">
          <el-select v-model="ruleForm.floor" placeholder="请选择">
            <el-option
              v-for="item in floorOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose" size="small">取 消</el-button>
        <el-button type="primary" @click="submit" size="small">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { ApiHouseTemplate } from "@/api/building";
import { searchEnableTagDic } from "@/api/common";
export default {
  name: "RoomList",
  props: ["breadcrumbs"],
  data() {
    return {
      ruleForm: {
        type: "",
        area: "",
        name: "",
        floor: ""
      },
      rules: {
        name: [{ required: true, message: "请输入", trigger: "blur" }],
        type: [{ required: true, message: "请选择", trigger: "change" }],
        floor: [{ required: true, message: "请选择", trigger: "change" }]
      },
      data: [],
      visible: false,
      id: null,
      typeOptions: null,
      floorOptions: null
    };
  },
  computed: {
    title() {
      return this.id ? "编辑房间" : "新增房间";
    },
    columns() {
      if (this.$route.query.houseTemplateType != 1) {
        return [
          {
            label: "楼层",
            name: "floor"
          },
          {
            label: "房间名称",
            name: "name"
          },
          {
            label: "房间类型",
            name: "typeStr"
          },
          {
            label: "房间面积",
            name: "areaStr"
          },
          {
            label: "设备数",
            name: "deviceNum"
          }
        ];
      } else {
        return [
          {
            label: "房间名称",
            name: "name"
          },
          {
            label: "房间类型",
            name: "typeStr"
          },
          {
            label: "房间面积",
            name: "areaStr"
          },
          {
            label: "设备数",
            name: "deviceNum"
          }
        ];
      }
    }
  },
  mounted() {
    this.initOptions();
    this.refreshTableData();
    if (this.$route.query.houseTemplateType == 1) {
      this.floor = 1;
    }
  },
  methods: {
    initOptions() {
      ApiHouseTemplate.roomTypeOptions().then((res) => {
        this.typeOptions = res;
      });
      if (this.$route.query.houseTemplateType != 1) {
        searchEnableTagDic("floor").then((res) => {
          this.floorOptions = res;
        });
      }
    },
    refreshTableData() {
      ApiHouseTemplate.roomList(this.$route.query.houseTemplateId).then((res) => {
        res.forEach((e) => {
          e.areaStr = e.area + "m²";
        });
        this.data = res;
      });
    },
    handleClose() {
      this.visible = false;
      this.$refs.ruleForm && this.$refs.ruleForm.resetFields();
      this.id = null;
    },
    submit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let m = {
            ...this.ruleForm,
            projectId: Number(this.$route.query.projectId),
            houseTemplateId: Number(this.$route.query.houseTemplateId)
          };
          if (this.id) {
            m.id = this.id;
          }
          if (this.$route.query.houseTemplateType == 1) {
            m.floor = 1;
          }
          this.$store.commit("OPEN_FULL_SCREEN_LOADING");
          let func = m.id ? "updateRoom" : "addRoom";
          ApiHouseTemplate[func](m)
            .then(() => {
              this.$message.success(`${m.id ? "修改" : "新增"}成功`);
              this.handleClose();
              this.refreshTableData();
            })
            .finally(() => {
              this.$store.commit("CLOSE_FULL_SCREEN_LOADING");
            });
        }
      });
    },
    addDevice(row) {
      this.$router.push({
        path: "/building/projectList/projectDetail/room/roomDeviceList",
        query: {
          ...this.$route.query,
          roomId: row.id,
          breadcrumbs: JSON.stringify(this.breadcrumbs)
        }
      });
    },
    editItem(row) {
      this.visible = true;
      this.id = row.id;
      this.$nextTick(() => {
        this.ruleForm.type = row.type;
        this.ruleForm.area = row.area;
        this.ruleForm.name = row.name;
        this.ruleForm.floor = row.floor;
      });
    },
    delItem(id) {
      this.$delItem(() => {
        let m = {
          id,
          projectId: Number(this.$route.query.projectId)
        };
        this.$store.commit("OPEN_FULL_SCREEN_LOADING");
        ApiHouseTemplate.delRoom(m)
          .then(() => {
            this.$message.success("删除成功");
            this.refreshTableData();
          })
          .finally(() => {
            this.$store.commit("CLOSE_FULL_SCREEN_LOADING");
          });
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.el-input,
.el-select {
  width: $input-width;
}
</style>
