<template>
  <div class="flex" v-if="row">
    <el-select v-model="row.attr.value" placeholder="设备数据点" style="width: 180px" @change="changeAttr">
      <el-option :label="item.name" :value="item.code" v-for="(item, idx) in row.attr.options" :key="idx"></el-option>
    </el-select>
    <el-select v-model="row.attrOp.value" placeholder="操作符" style="width: 80px; margin: 0 10px">
      <el-option :label="item" :value="idx" v-for="(item, idx) in op" :key="idx"></el-option>
    </el-select>
    <template v-if="row">
      <el-select
        v-if="row.attr.type != 2"
        v-model="row.attrValue.value"
        placeholder="数据点对应值"
        style="width: 180px"
      >
        <el-option v-for="info in row.attrValue.options" :key="info.code" :label="info.name" :value="info.code">
        </el-option>
      </el-select>
      <el-input-number
        v-else
        v-model="row.attrValue.value"
        :min="parseFloat(row.attrValue.scopeVO.min)"
        :max="row.attrValue.scopeVO | max"
        :precision="parseFloat(row.attrValue.scopeVO.precision)"
        :step="parseFloat(row.attrValue.scopeVO.step)"
        :step-strictly="row.attrValue.scopeVO && row.attrValue.scopeVO.step != 6"
      ></el-input-number>
    </template>
  </div>
</template>

<script>
export default {
  name: "EnvironmentItem",
  components: {},
  props: {
    row: {}
  },
  data() {
    return {
      op: ["=", ">", "≥", "<", "≤"]
    };
  },
  filters: {
    max(scope) {
      if (scope.step == 6) {
        return parseFloat(scope.max) + 5;
      }
      return parseFloat(scope.max);
    }
  },
  methods: {
    changeAttr() {
      this.row.attrValue.value = null;
      this.row.attrOp.value = null;
      let item = this.row.attr.options.find((e) => e.code == this.row.attr.value);
      this.row.attrValue = {
        label: null,
        value: null,
        options: item.infos,
        scopeVO: item.scopeVO
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.flex {
  padding-top: 10px;
  padding-bottom: 10px;
  height: 60px;
  border-bottom: 1px solid #ebeef5;
}
.el-checkbox,
.item {
  margin: 0;
}
.el-checkbox {
  padding-left: 10px;
  padding-right: 10px;
}
.el-checkbox {
  display: block;
  line-height: 40px;
  min-width: 140px;
}
</style>
