<!--
 * @Description: 批量新增楼房 - 户数填写
 * @Author: zhongyuanrui
 * @LastEditors: zhongyuanrui
 * @FilePath: \homeauto-web-v3.0\src\views\building\projectDetail\buildingSetting\AddBatchRoomNumForm.vue
-->
<template>
  <el-form class="add-batch-room-num-form" ref="form" label-width="90px" label-position="left" :model="formData">
    <div v-for="(item, index) in data.units" :key="index">
      <div class="unit-name">单元{{ index + 1 }}</div>
      <el-form-item label="户数" :prop="`roomNum${index + 1}`" :rules="formItemRule">
        <el-input v-model.number="formData[`roomNum${index + 1}`]" placeholder="请填写户数" @change="change"></el-input>
      </el-form-item>
    </div>
  </el-form>
</template>

<script>
import { deepClone } from "@/utils/util";

export default {
  name: "AddBatchRoomNumForm",
  model: {
    prop: "data",
    event: "change"
  },
  props: {
    data: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      formData: {},
      formItemRule: [
        { required: true, message: "请填写户数", trigger: "blur" },
        { type: "number", message: "户数必须为数字值" }
      ]
    };
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {
      this.data.units.forEach((item, index) => {
        this.$set(this.formData, `roomNum${index + 1}`, item);
      });
    },
    change() {
      let _data = deepClone(this.formData);
      let list = [];
      for (const key in this.formData) {
        list.push(this.formData[key]);
      }
      _data.units = list;
      this.$emit("change-room-num", list);
      this.$emit("change", _data);
    }
  }
};
</script>

<style lang="scss" scoped>
.add-batch-room-num-form {
  .unit-name {
    margin: 0 10px 5px;
  }
}
</style>
