<template>
  <div style="height: 100%">
    <el-tabs v-model="active">
      <el-tab-pane v-for="item in tabs" :key="item.label" :label="item.label" :name="item.label"></el-tab-pane>
    </el-tabs>
    <keep-alive :include="keepAliveList">
      <router-view :breadcrumbs="breadcrumbs" />
    </keep-alive>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "ProjectDetail",
  data() {
    return {
      active: this.$route.query.tab,
      tabs: [
        {
          path: "/building/projectList/projectDetail/baseInfo",
          label: "基本信息"
        },
        {
          path: "/building/projectList/projectDetail/houseTemplate",
          label: "户型配置"
        },
        {
          path: "/building/projectList/projectDetail/buildingSetting",
          label: "楼房配置"
        },
        {
          path: "/building/projectList/projectDetail/opLogging",
          label: "操作日志"
        },
        {
          path: "/building/projectList/projectDetail/screenConfig",
          label: "大屏配置"
        }
      ],
      breadcrumbs: []
    };
  },
  watch: {
    active() {
      this.changeLabel();
      this.updateBreadcrumbs();
    },
    $route() {
      if (this.isTabPage) {
        this.updateBreadcrumbs();
      }
    }
  },
  computed: {
    ...mapGetters(["keepAliveList"]),
    isTabPage() {
      let idx = this.tabs.findIndex((e) => e.path == this.$route.path);
      return idx !== -1;
    }
  },
  mounted() {
    this.updateBreadcrumbs();
  },
  destroyed() {
    this.$store.commit("SET_BREADCRUMBS", []);
  },
  methods: {
    changeLabel() {
      let { projectId, projectName, realestateId, projectType, gatewayFlag, sysProductId } = { ...this.$route.query };
      let item = this.tabs.find((e) => {
        return e.label == this.active;
      });
      if (item) {
        this.$router.replace({
          path: item.path,
          query: {
            projectId,
            realestateId,
            projectName,
            projectType,
            gatewayFlag,
            sysProductId,
            tab: item.label
          }
        });
      }
    },
    updateBreadcrumbs() {
      if (this.$route.breadcrumbs) {
        return;
      }
      let breadcrumbs = [
        {
          path: "/building/list",
          title: "楼盘管理"
        },
        {
          path: "/building/projectList",
          title: `项目管理 (${this.$route.query.projectName})`
        },
        {
          path: this.$route.path,
          title: this.$route.query.tab,
          query: {
            ...this.$route.query
          }
        }
      ];
      this.breadcrumbs = breadcrumbs;
      if (this.isTabPage) {
        this.$store.commit("SET_BREADCRUMBS", breadcrumbs);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.el-tabs {
  height: 60px;
  box-shadow: 0px 0px 4px 0px rgba(187, 187, 187, 0.3);
  border-radius: 4px;
  background: #fff;
}
::v-deep .el-tabs__nav-wrap::after {
  display: none;
}
::v-deep .el-tabs__item {
  padding: 0 20px !important;
}
::v-deep .el-tabs__nav {
  margin: 0;
  line-height: 60px;
  height: 60px;
}
::v-deep .el-tabs__item {
  font-size: 14px;
  color: #666666;
}
::v-deep .el-tabs__item.is-active {
  color: #00c4b3;
  font-weight: 500;
}

::v-deep .el-tabs__active-bar {
  left: -20px;
  width: 96px !important;
}
</style>
