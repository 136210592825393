<template>
  <form-dialog
    title="楼房号更新"
    :formItems="formItems"
    :formData="formData"
    :confirmLoading="confirmLoading"
    @close="$emit('close')"
    @confirm="confirm"
  >
    <template v-slot:url>
      <upload-apk
        :uploadApiFunc="uploadApiFunc"
        v-model="formData.url"
        :fileName="formData.fileName"
        @upload-success="uploadSuccess"
      ></upload-apk>
    </template>
    <template v-slot:download>
      <el-button type="primary" size="small" @click="downloadTemplate" :loading="downloadLoading">模板下载</el-button>
    </template>
    <template v-slot:tip>
      <div style="display: flex; gap: 10px; line-height: 1.2">
        <i class="el-icon-info"></i>
        <span>请先下载模板，将变更后的楼栋单元房号与原楼房号逐一对应填写完毕后导入</span>
      </div>
    </template>
  </form-dialog>
</template>

<script>
import { ApiBuildingSetting } from "@/api/building";
import { downLoad } from "@/utils/util";

export default {
  name: "UpdateDoorplateDialog",
  props: {
    projectId: {
      type: [String, Number],
      default: null
    }
  },
  data() {
    return {
      uploadApiFunc: ApiBuildingSetting.familyCodeChangeUpload,
      formItems: [
        {
          label: "选择文件",
          prop: "url",
          slot: true,
          rules: [{ required: true, message: "请选择", trigger: "change" }]
        },
        {
          label: "",
          prop: "download",
          slot: true
        },
        {
          label: "",
          prop: "tip",
          slot: true
        }
      ],
      formData: {},
      downloadLoading: false,
      confirmLoading: false
    };
  },
  methods: {
    uploadSuccess({ name }) {
      this.formData.fileName = name;
    },
    async downloadTemplate() {
      this.downloadLoading = true;
      const { filename, blob } = await ApiBuildingSetting.familyCodeChangeTemplate(this.projectId).finally(() => {
        this.downloadLoading = false;
      });
      downLoad(blob, decodeURI(filename));
    },
    async confirm() {
      const params = {
        projectId: this.projectId,
        ...this.formData
      };
      this.confirmLoading = true;
      await ApiBuildingSetting.familyCodeChangeSave(params).finally(() => {
        this.confirmLoading = false;
      });
      this.$message.success("更新成功");
      this.$emit("success");
    }
  }
};
</script>

<style lang="scss" scoped></style>
