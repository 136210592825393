<!--
 * @Description: 新增出发条件弹窗
 * @Author: hongyan.yang
 * @LastEditors: zhongyuanrui
 * @FilePath: \homeauto-web-v3.0\src\views\building\components\SceneTaskAddItem.vue
-->
<template>
  <el-dialog
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    title="选择设备"
    :visible.sync="dialogTableVisible"
    width="600px"
    :before-close="handleClose"
  >
    <div class="tab">
      <span>楼层</span>
      <el-radio-group v-model="floor" @change="clearDevice">
        <el-radio-button label="全部" border></el-radio-button>
        <el-radio-button v-for="(item, idx) in floorOptions" :key="idx" :label="item" border></el-radio-button>
      </el-radio-group>
    </div>
    <div class="tab" style="margin-bottom: 20px">
      <span>房间</span>
      <el-radio-group v-model="room" @change="clearDevice">
        <el-radio-button label="全部" border></el-radio-button>
        <el-radio-button v-for="(item, idx) in subRoomOptions" :key="idx" :label="item" border></el-radio-button>
      </el-radio-group>
    </div>

    <el-table
      ref="table"
      :data="filterData"
      border
      :header-cell-style="{ background: '#fafafa' }"
      @select="handleDeviceChange"
    >
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column property="deviceName" label="设备名称"></el-table-column>
      <el-table-column property="productName" label="产品名称"></el-table-column>
    </el-table>

    <el-form v-if="device" :form="form" ref="form" :inline="true" style="margin-top: 20px">
      <el-form-item label="设备状态">
        <el-select v-model="form.attributeCode" placeholder="设备数据点（操作属性）" size="small" style="width: 190px">
          <el-option :label="item.name" :value="item.code" v-for="item in device.attributes" :key="item.id"></el-option>
        </el-select>
      </el-form-item>
      <template v-if="form.attributeCode">
        <el-form-item v-if="nowAttr.type == 2">
          <el-select v-model="form.operatorType" placeholder="符号" size="small" style="width: 80px">
            <el-option :label="item" :value="idx" v-for="(item, idx) in op" :key="idx"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-input-number
            v-if="nowAttr.type == 2"
            v-model="form.val"
            :step="Number(nowAttr.scopeVO.step)"
            :min="Number(nowAttr.scopeVO.min)"
            :max="Number(nowAttr.scopeVO.max)"
            size="small"
            placeholder="数据点对应值（属性值）"
            style="width: 190px"
          ></el-input-number>
          <el-select v-else v-model="form.val" placeholder="数据点对应值（属性值）" size="small" style="width: 190px">
            <el-option :label="item.name" :value="item.code" v-for="item in nowAttr.infos" :key="item.code"></el-option>
          </el-select>
        </el-form-item>
      </template>
    </el-form>

    <div slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="saveEnvCondition">保 存</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { deepClone } from "@/utils/util";

export default {
  name: "TaskAddItem",
  components: {},
  props: {
    allDevices: {},
    floorOptions: {},
    roomOptions: {}
  },
  data() {
    return {
      dialogTableVisible: false,
      floor: "全部",
      room: "全部",
      device: null,
      form: {
        attributeCode: "",
        operatorType: null,
        val: ""
      },
      // deviceAttrValue: "",
      op: ["=", ">", "≥", "<", "≤"],
      nowAttr: null
    };
  },
  computed: {
    filterData() {
      // 过滤出传感器13和温控面板12
      if (!this.allDevices) return;
      let data = this.allDevices;
      if (this.floor && this.floor != "全部") {
        data = data.filter((e) => e.floorName == this.floor);
      }
      if (this.room && this.room != "全部") {
        data = data.filter((e) => e.roomName == this.room);
      }
      return data;
    },
    subRoomOptions() {
      if (!this.roomOptions) {
        return;
      }
      let roomOptions = this.roomOptions.filter((e) => e.hasSensor || e.hasPanel);
      if (roomOptions.length) {
        let rooms = roomOptions;
        if (this.floor != "全部") {
          rooms = roomOptions.filter((e) => {
            return e.floor == this.floor.split("楼")[0];
          });
        }
        return rooms.map((e) => e.name);
      }
      return [];
    }
  },
  watch: {
    floor() {
      this.room = "全部";
    },
    "form.attributeCode"(e) {
      if (!e || !this.device) {
        return;
      }
      this.nowAttr = this.device.attributes.find((attr) => attr.code == e);
      if (this.nowAttr.type == 1) {
        this.form.operatorType = 0;
      }
    }
  },
  mounted() {},
  methods: {
    clearDevice() {
      this.device = null;
      this.form.attributeCode = "";
      this.form.operatorType = null;
      this.form.val = "";
    },
    handleDeviceChange(val) {
      if (val.length > 1) {
        let delRow = val.shift();
        this.$refs.table.toggleRowSelection(delRow, false);
      }
      this.clearDevice();
      this.device = deepClone(val[0]);
    },
    saveEnvCondition() {
      if (!this.form.attributeCode) {
        this.$message.warning("请选择设备数据点");
        return;
      }

      if (!this.form.operatorType && this.form.operatorType !== 0) {
        this.$message.warning("请选择关系");
        return;
      }
      if (!this.form.val) {
        this.$message.warning("请设置设备值");
        return;
      }

      let { id, floor, roomName, productCode, productId, deviceName, deviceSn, attributes } = this.device;
      let attr = attributes.find((e) => e.code === this.nowAttr.code);
      let tableItem = {
        id,
        floor,
        roomName,
        floorAndRoom: floor + "楼" + roomName,
        productCode,
        productId,
        deviceName,
        deviceSn,
        attr: {
          label: attr.name,
          value: this.form.attributeCode,
          options: attributes,
          type: attr.type
        },
        attrOp: {
          label: this.op[this.form.operatorType],
          value: this.form.operatorType
        },
        attrValue: {
          label: attr.type == 1 ? attr.infos.find((e) => e.code == this.form.val)?.name : null,
          value: this.form.val,
          options: attr.infos,
          scopeVO: attr.scopeVO
        }
      };
      console.log(tableItem);

      // let tableItem = this.attributesToattributeVOS(this.device);

      this.handleClose();
      this.$emit("saveEnvDevice", tableItem);
    },
    attributesToattributeVOS(device) {
      let attributeVOS = deepClone(device.attributes);
      // let attr = attributeVOS.find((e) => e.code === this.nowAttr.code);
      // if (attr) {
      //   attr.operatorType = this.form.operatorType;
      //   attr.val = this.form.val;
      //   attr.selected = true;
      // }
      let sel = deepClone(device);
      delete sel.attributes;
      return {
        ...sel,
        attributeVOS,
        // 每次只有单一一个属性
        // attribute: attr,
        operatorType: this.form.attributeCode,
        attributeCode: this.form.attributeCode,
        targetVal: this.form.val
      };
    },
    handleClose() {
      this.floor = "全部";
      this.room = "全部";
      this.nowAttr = null;
      this.clearDevice();
      this.$refs.table.clearSelection();
      this.dialogTableVisible = false;
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__body {
  padding-top: 10px;
}
.tab > span {
  display: inline-block;
  width: 40px;
}
.tab {
  min-height: 64px;
  line-height: 40px;
  & > span {
    display: inline-block;
    width: 40px;
    vertical-align: top;
    padding-top: 6px;
  }
  & > .el-radio-group {
    vertical-align: top;
    width: 460px;
  }
}
.el-radio-button {
  margin: 6px 12px;
  text-align: center;
}
::v-deep .el-radio-button__inner {
  margin-right: 0 !important;
  padding: 0;
  border: 1px solid #d8d8d8 !important;
  border-radius: 4px !important;
  min-width: 76px;
  height: 40px;
  line-height: 30px;
}
::v-deep .el-radio-button__orig-radio:checked + .el-radio-button__inner {
  color: $primary-color;
  border-color: transparent !important;
}
::v-deep .el-table__header-wrapper .el-table__header .el-checkbox {
  display: none;
}
</style>
