<template>
  <div>
    <base-list-page
      :tableModel="tableModel"
      @change="refreshTableData"
      addBtn="新增设备"
      @addItem="addItem"
      @handleBtn="handleBtn"
      @delItem="delItem"
      :hideBtnIcon="true"
    />
    <el-dialog
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :title="title"
      :visible.sync="visible"
      width="540px"
      :before-close="handleClose"
    >
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="140px">
        <el-form-item label="是否属于系统设备" prop="systemFlag" v-if="$route.query.sysProductId">
          <el-radio-group v-model="ruleForm.systemFlag" @change="changeDeviceSystemType">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="产品品类" prop="categoryId">
          <el-select v-model="ruleForm.categoryId" placeholder="请选择" @change="changeCategory" :disabled="!!id">
            <el-option
              v-for="item in categoryOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="产品名称" prop="productId">
          <el-select
            v-model="ruleForm.productId"
            filterable
            placeholder="请选择"
            @change="changeProduct"
            :disabled="!!id"
          >
            <el-option v-for="item in productOptions" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="产品品牌">
          <el-input v-model="brand" disabled></el-input>
        </el-form-item>
        <el-form-item label="型号">
          <el-input v-model="model" disabled></el-input>
        </el-form-item>
        <el-form-item label="设备名称" prop="name">
          <el-input v-model="ruleForm.name" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="设备编号" prop="sn" v-if="$route.query.gatewayFlag == 1">
          <el-input v-model="ruleForm.sn" placeholder="请输入" type="number" :disabled="title != '新增设备'"></el-input>
        </el-form-item>
        <el-form-item label="设备地址" prop="addressCode">
          <el-input v-model="ruleForm.addressCode" placeholder="请输入"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose" size="small">取 消</el-button>
        <el-button type="primary" @click="submit" size="small">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { ApiRoomDevice } from "@/api/building";
import { ApiProduct } from "@/api/product";
import { baseTableProperty, colProperty, btnProperty } from "@/components/baseTable/baseTableProperty";

export default {
  name: "RoomDeviceList",
  components: {},
  props: {},
  data() {
    return {
      tableModel: null,
      ruleForm: {
        categoryId: "",
        productId: "",
        name: "",
        sn: "",
        systemFlag: "",
        addressCode: ""
      },
      rules: {
        systemFlag: [{ required: true, message: "请选择", trigger: "change" }],
        categoryId: [{ required: true, message: "请选择", trigger: "change" }],
        productId: [{ required: true, message: "请选择", trigger: "change" }],
        name: [{ required: true, message: "请输入", trigger: "blur" }],
        sn: [{ required: true, message: "请输入", trigger: "blur" }]
      },
      brand: "",
      model: "",
      visible: false,
      commonCategoryOptions: null,
      systemCategoryOptions: null,
      productOptions: null,
      id: null,
      typeOptions: null
    };
  },
  computed: {
    title() {
      return this.id ? "编辑设备" : "新增设备";
    },
    categoryOptions() {
      if (this.ruleForm.systemFlag === 0 || !this.$route.query.sysProductId) {
        return this.commonCategoryOptions;
      } else if (this.ruleForm.systemFlag === 1) {
        return this.systemCategoryOptions;
      } else {
        return [];
      }
    }
  },
  mounted() {
    if (!this.$route.query.sysProductId) {
      this.ruleForm.systemFlag = 0;
    }
    this.initOptions();
    this.initTableList();
    this.initBreadcrumbs();
  },
  methods: {
    initBreadcrumbs() {
      let query = this.$route.query;
      let breadcrumbs = JSON.parse(query.breadcrumbs);
      breadcrumbs.push({
        path: this.$route.path,
        title: "添加设备",
        query: { ...query }
      });
      this.$store.commit("SET_BREADCRUMBS", breadcrumbs);
    },
    initOptions() {
      ApiProduct.categoryOptions().then((res) => {
        this.commonCategoryOptions = res;
      });
      if (this.$route.query.sysProductId) {
        ApiRoomDevice.systemProductCategory(this.$route.query.sysProductId).then((res) => {
          this.systemCategoryOptions = res;
        });
      }
    },
    initTableList() {
      let model = new baseTableProperty();
      model.colProperty = [
        colProperty.Init("设备编号", "sn", "200px"),
        colProperty.Init("设备名称", "name"),
        colProperty.Init("设备地址", "addressCode"),
        colProperty.Init("产品名称", "productName"),
        colProperty.Init("产品品类", "categoryName"),
        colProperty.Init("产品品牌", "brandName"),
        colProperty.Init("型号", "model")
      ];
      this.tableModel = model;
      model.opBtns = [btnProperty.Init("编辑"), btnProperty.Init("删除")];
      model.opBtnsWidth = "300px";
      this.refreshTableData();
    },
    refreshTableData() {
      ApiRoomDevice.list(this.$route.query.roomId).then((res) => {
        this.tableModel.setData(res);
      });
    },
    changeCategory(e, productId) {
      if (this.ruleForm.systemFlag === 0) {
        ApiRoomDevice.productOptions(e).then((res) => {
          this.productOptions = res;
          this.ruleForm.productId = productId || "";
        });
      } else if (this.ruleForm.systemFlag === 1) {
        let m = {
          categoryId: e,
          sysPid: this.$route.query.sysProductId
        };
        ApiRoomDevice.systemProduct(m).then((res) => {
          this.productOptions = res;
          this.ruleForm.productId = productId || "";
        });
      }
    },
    changeDeviceSystemType() {
      this.ruleForm.productId = "";
      this.ruleForm.categoryId = "";
    },
    changeProduct(productId) {
      let product = this.productOptions.find((e) => {
        return e.id == productId;
      });
      this.model = product.model;
      this.brand = product.brand;
    },
    addItem() {
      this.visible = true;
    },
    handleBtn({ btn, data }) {
      switch (btn.label) {
        case "编辑":
          this.editItem(data);
          break;
      }
    },
    editItem(row) {
      this.visible = true;
      this.id = row.id;
      this.$nextTick(() => {
        this.ruleForm.addressCode = row.addressCode;
        this.ruleForm.systemFlag = row.systemFlag;
        this.ruleForm.categoryId = Number(row.categoryId);
        this.ruleForm.name = row.name;
        this.ruleForm.sn = row.sn;
        this.ruleForm.name = row.name;
        this.model = row.model;
        this.brand = row.brandName;
        this.ruleForm.productId = row.productId;
        this.changeCategory(row.categoryId, row.productId);
      });
    },
    delItem(id) {
      let m = {
        id,
        projectId: Number(this.$route.query.projectId)
      };
      this.$store.commit("OPEN_FULL_SCREEN_LOADING");
      ApiRoomDevice.del(m)
        .then(() => {
          this.$message.success("删除成功");
          this.refreshTableData();
        })
        .finally(() => {
          this.$store.commit("CLOSE_FULL_SCREEN_LOADING");
        });
    },
    handleClose() {
      this.visible = false;
      this.$refs.ruleForm && this.$refs.ruleForm.resetFields();
      this.brand = "";
      this.model = "";
      this.ruleForm.sn = "";
      this.id = null;
      this.productOptions = null;
    },
    submit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let m = {
            ...this.ruleForm,
            projectId: Number(this.$route.query.projectId),
            roomId: Number(this.$route.query.roomId),
            houseTemplateId: Number(this.$route.query.houseTemplateId)
          };
          if (this.id) {
            m.id = this.id;
          }
          this.$store.commit("OPEN_FULL_SCREEN_LOADING");
          let func = m.id ? "update" : "add";
          ApiRoomDevice[func](m)
            .then(() => {
              this.$message.success(`${m.id ? "修改" : "新增"}成功`);
              this.handleClose();
              this.refreshTableData();
            })
            .finally(() => {
              this.$store.commit("CLOSE_FULL_SCREEN_LOADING");
            });
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.el-input,
.el-select {
  width: $input-width;
}
</style>
