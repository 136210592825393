<template>
  <div class="flex" v-if="item">
    <el-checkbox v-model="selected" :label="item.name" @change="changeCheckbox"></el-checkbox>
    <el-select v-model="item.val" placeholder="请选择" v-if="item.type != 2" style="width: 180px" :disabled="!selected">
      <el-option v-for="info in item.infos" :key="info.code" :label="info.name" :value="info.code"> </el-option>
    </el-select>
    <el-input-number
      v-else
      :disabled="!selected"
      v-model="item.val"
      @change="changeStep($event, item)"
      :min="parseFloat(item.scopeVO.min)"
      :max="item.scopeVO | max"
      :precision="parseFloat(item.scopeVO.precision)"
      :step="parseFloat(item.scopeVO.step)"
      :step-strictly="item.scopeVO && item.scopeVO.step != 6"
    ></el-input-number>
  </div>
</template>

<script>
export default {
  name: "TaskItem",
  components: {},
  props: {
    item: {}
  },
  data() {
    return {
      selected: this.item.selected
    };
  },
  watch: {
    "item.selected"(v) {
      this.selected = v;
    }
  },
  filters: {
    max(scope) {
      if (scope.step == 6) {
        return parseFloat(scope.max) + 5;
      }
      return parseFloat(scope.max);
    }
  },
  methods: {
    changeCheckbox(e) {
      this.$emit("changeCheckbox", e);
    },
    changeStep(now, item) {
      if (item.scopeVO && item.scopeVO.step == 6) {
        if (now >= item.scopeVO.max) {
          item.val = item.scopeVO.max;
        } else if (now == 94) {
          item.val = 96;
        } else if (now % item.scopeVO.step != 0) {
          this.$nextTick(() => {
            item.val = Math.round(now / item.scopeVO.step) * item.scopeVO.step;
          });
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.flex {
  padding-top: 10px;
  padding-bottom: 10px;
  height: 60px;
  border-bottom: 1px solid #ebeef5;
}
.el-checkbox,
.item {
  margin: 0;
}
.el-checkbox {
  padding-left: 10px;
  padding-right: 10px;
}
.el-checkbox {
  display: block;
  line-height: 40px;
  min-width: 140px;
}
</style>
