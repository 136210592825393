<!--
 * @Description: 
 * @Author: zhongyuanrui
 * @LastEditors: zhongyuanrui
 * @FilePath: \homeauto-web-v3.0\src\views\building\projectDetail\BaseInfo.vue
-->
<template>
  <base-card title="基本信息" class="base-info">
    <el-form ref="form" label-width="130px">
      <el-col :span="12" v-for="item in formItems" :key="item.prop">
        <el-form-item :label="item.label" :prop="item.prop">
          <img style="width: 146px" v-if="item.type === 'image'" :src="formData[item.prop]" />
          <span v-else class="base-info-item-content">{{ formData[item.propName ? item.propName : item.prop] }}</span>
        </el-form-item>
      </el-col>
    </el-form>
  </base-card>
</template>

<script>
import { ApiProject } from "@/api/building";

export default {
  name: "BaseInfo",
  data() {
    return {
      formItems: [
        {
          label: "项目名称",
          prop: "name"
        },
        {
          label: "项目所用系统类型",
          prop: "sysProductId",
          propName: "sysProductName"
        },
        {
          label: "楼盘名称",
          prop: "realestateName"
        },
        {
          label: "项目类型",
          prop: "projectType",
          propName: "projectTypeName"
        },
        {
          label: "项目地址",
          prop: "pathName"
        },
        {
          label: "是否含有网关",
          prop: "gatewayFlag",
          propName: "gatewayFlagStr"
        },
        {
          label: "项目状态",
          prop: "status",
          propName: "statusStr"
        },
        {
          label: "是否有数采",
          prop: "localGwFlag",
          propName: "localGwFlagStr"
        },
        {
          label: "开发商",
          prop: "developerName"
        },

        {
          label: "项目连接方式",
          prop: "interactType",
          propName: "interactTypeStr"
        },
        {
          label: "项目经理",
          prop: "projectManager"
        },

        {
          label: "485连接设备",
          prop: "connectDevice",
          propName: "connectDeviceStr"
        },
        {
          label: "项目描述",
          prop: "remark"
        },

        {
          label: "离线总览展示",
          prop: "offlineKanbanFlag",
          propName: "offlineKanbanFlagStr"
        },
        {
          label: "公司logo",
          prop: "logoPath",
          type: "image"
        }
      ],
      formData: {}
    };
  },
  mounted() {
    this.initFormData();
  },
  methods: {
    async initFormData() {
      const { projectId } = this.$route.query;
      if (!projectId) return;
      const res = await ApiProject.details(projectId);
      this.formData = {
        ...res
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.base-info {
  margin: 20px 0 0;
  &-handle {
    margin-right: 20px;
  }
  &-item-content {
    width: 350px;
    word-wrap: break-word;
  }
  ::v-deep .el-input,
  ::v-deep .el-textarea {
    width: 232px;
  }
}
</style>
