<template>
  <div>
    <card-table title="场景列表" opBtn="新增场景" :showTable="false" @edit="addItem">
      <el-table :data="data" :span-method="objectSpanMethod" border>
        <el-table-column prop="name" label="场景名称" width="200" align="center"> </el-table-column>
        <el-table-column prop="condition" label="触发条件"> </el-table-column>
        <el-table-column prop="task" label="执行任务"> </el-table-column>
        <el-table-column prop="defaultFlagStr" label="是否为预设场景" width="200" align="center"> </el-table-column>
        <el-table-column prop="commonFlag" label="是否为常用场景" width="200" align="center">
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.commonFlag"
              :inactive-value="0"
              :active-value="1"
              @change="changeCommonFlag(scope.row)"
              active-color="#00c4b3"
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope" v-if="scope.row.immutableFlag == 1">
            <table-button size="mini" type="primary" @click="editItem(scope.row)">编辑</table-button>
            <table-button size="mini" type="danger" @click="delItem(scope.row.id)">删除</table-button>
          </template>
        </el-table-column>
      </el-table>
    </card-table>
  </div>
</template>

<script>
import { ApiRoomScene } from "@/api/building";
export default {
  name: "SceneList",
  data() {
    return {
      data: []
    };
  },
  props: ["breadcrumbs"],
  mounted() {
    this.refreshTableData();
  },
  methods: {
    objectSpanMethod({ row, columnIndex }) {
      if (columnIndex === 0 || columnIndex === 3 || columnIndex === 4) {
        if (row.show) {
          return {
            rowspan: row.rowspan,
            colspan: 1
          };
        } else {
          return {
            rowspan: 0,
            colspan: 0
          };
        }
      }
      return;
    },
    refreshTableData() {
      ApiRoomScene.list(this.$route.query.houseTemplateId).then((res) => {
        let tableArray = [];
        res.forEach((e) => {
          if (e.sketch) {
            e.sketch?.forEach((sk, idx) => {
              tableArray.push({
                ...e,
                condition: sk.condition,
                task: sk.task,
                defaultFlagStr: e.defaultFlag == 1 ? "是" : "否",
                rowspan: e.sketch.length,
                show: idx === 0
              });
            });
          } else {
            tableArray.push({
              ...e,
              condition: "",
              task: "",
              rowspan: 1,
              show: true,
              defaultFlagStr: e.defaultFlag == 1 ? "是" : "否"
            });
          }
        });
        // this.data = tableArray.length ? tableArray : res;
        this.data = tableArray;
      });
    },
    addItem() {
      this.$router.push({
        path: "/building/projectList/projectDetail/scene/SceneDetail",
        query: { ...this.$route.query, breadcrumbs: JSON.stringify(this.breadcrumbs) }
      });
    },
    editItem(row) {
      this.$router.push({
        path: "/building/projectList/projectDetail/scene/SceneDetail",
        query: { ...this.$route.query, sceneId: row.id, breadcrumbs: JSON.stringify(this.breadcrumbs) }
      });
    },
    delItem(id) {
      this.$delItem(() => {
        let m = {
          id,
          projectId: Number(this.$route.query.projectId)
        };
        this.$store.commit("OPEN_FULL_SCREEN_LOADING");
        ApiRoomScene.del(m)
          .then(() => {
            this.$message.success("删除成功");
            this.refreshTableData();
          })
          .finally(() => {
            this.$store.commit("CLOSE_FULL_SCREEN_LOADING");
          });
      });
    },
    changeCommonFlag(row) {
      ApiRoomScene.swtichCommonFlag(row.id).catch(() => {
        row.commonFlag = row.commonFlag == 1 ? 0 : 1;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.el-input,
.el-select {
  width: $input-width;
}
::v-deep .el-table__row td:nth-child(2) {
  border-right: 1px solid #ebeef5 !important;
}
</style>
