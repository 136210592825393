<!--
 * @Description: 
 * @Author: zhongyuanrui
 * @LastEditors: zhongyuanrui
 * @FilePath: \homeauto-web-v3.0\src\views\building\projectDetail\buildingSetting\AddBatchDialog.vue
-->
<template>
  <form-dialog
    title="批量新增楼房"
    :cancelVisible="false"
    :confirmVisible="active === 2"
    :confirmLoading="confirmLoading"
    @close="$emit('close')"
    @confirm="confirm"
  >
    <component
      :ref="currentComponentName"
      v-bind:is="currentComponent"
      :projectId="projectId"
      v-model="formDataList[active]"
      @change-units-num="changeUnitsNum"
      @change-room-num="changeRoomNum"
    ></component>
    <template v-slot:footerCenter>
      <el-button v-if="active > 0" type="primary" size="small" @click="previous">上一步</el-button>
      <el-button v-if="active < 2" type="primary" size="small" @click="next">下一步</el-button>
    </template>
  </form-dialog>
</template>

<script>
import { ApiBuildingSetting } from "@/api/building";
const components = [
  {
    name: "addBatchFloorForm",
    component: () => import(/* webpackChunkName: "project" */ "./AddBatchFloorForm")
  },
  {
    name: "addBatchRoomNumForm",
    component: () => import(/* webpackChunkName: "project" */ "./AddBatchRoomNumForm")
  },
  {
    name: "addBatchRoomNoForm",
    component: () => import(/* webpackChunkName: "project" */ "./AddBatchRoomNoForm")
  }
];

export default {
  name: "AddBatchDialog",
  props: {
    projectId: {
      type: String,
      default: ""
    },
    realestateId: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      active: 0,
      formDataList: [
        {
          buildingCode: "",
          unitsNum: "",
          floorStart: "",
          floorEnd: "",
          skipFloor: []
        },
        {
          units: []
        },
        {
          prefix: "",
          units: [],
          suffix: ""
        }
      ],
      confirmLoading: false
    };
  },
  computed: {
    // 当前组件
    currentComponent() {
      return components[this.active].component;
    },
    // 当前组件名称
    currentComponentName() {
      return components[this.active].name;
    }
  },
  methods: {
    // 修改单元数
    changeUnitsNum(value) {
      this.$set(this.formDataList[1], "units", [...new Array(Number(value)).fill("")]);
    },
    // 修改户数
    changeRoomNum(value) {
      let list = [];
      value.forEach((item) => {
        if (!Number(item)) return;
        let obj = {};
        let rooms = [];
        for (let i = 0; i < Number(item); i++) {
          let templateObj = { roomNo: "", templateId: "" };
          rooms.push(templateObj);
        }
        obj.rooms = rooms;
        list.push(obj);
      });
      this.$set(this.formDataList[2], "units", list);
    },
    // 上一步
    previous() {
      if (this.active === 0) return;
      this.active--;
    },
    // 下一步
    next() {
      this.$refs[this.currentComponentName].$refs.form.validate((valid) => {
        if (valid) {
          if (this.active === 2) return;
          this.active++;
        }
      });
    },
    // 确认
    confirm() {
      this.$refs[this.currentComponentName].$refs.form.validate(async (valid) => {
        if (valid) {
          const { buildingCode, floorStart, floorEnd, skipFloor } = this.formDataList[0];
          const { prefix, suffix, units } = this.formDataList[2];
          const params = {
            projectId: this.projectId,
            realestateId: this.realestateId,
            buildingCode,
            floor: `${floorStart},${floorEnd}`,
            skipFloor,
            prefix,
            suffix,
            units
          };
          this.confirmLoading = true;
          try {
            await ApiBuildingSetting.addBatch(params);
            this.$message.success("添加成功");
            this.$emit("add-success");
          } catch (error) {
            console.log(error);
          }
          this.confirmLoading = false;
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped></style>
