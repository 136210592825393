<!--
 * @Description: 新增执行设备弹窗
 * @Author: hongyan.yang
 * @LastEditors: zhongyuanrui
 * @FilePath: \homeauto-web-v3.0\src\views\building\components\SceneTaskAddItem.vue
-->
<template>
  <el-dialog
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    title="选择设备"
    :visible.sync="dialogTableVisible"
    width="540px"
    :before-close="handleClose"
  >
    <div class="tab">
      <span>楼层</span>
      <el-radio-group v-model="floor">
        <el-radio-button label="全部" border></el-radio-button>
        <el-radio-button v-for="(item, idx) in floorOptions" :key="idx" :label="item" border></el-radio-button>
      </el-radio-group>
    </div>
    <div class="tab" style="margin-bottom: 20px">
      <span>房间</span>
      <el-radio-group v-model="room">
        <el-radio-button label="全部" border></el-radio-button>
        <el-radio-button v-for="(item, idx) in subRoomOptions" :key="idx" :label="item" border></el-radio-button>
      </el-radio-group>
    </div>

    <el-table
      :data="filterData"
      border
      :header-cell-style="{ background: '#fafafa' }"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column property="deviceName" label="设备名称"></el-table-column>
      <el-table-column property="productName" label="产品名称"></el-table-column>
    </el-table>
    <div slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="saveDevice">保 存</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "TaskAddItem",
  components: {},
  props: {
    allDevices: {},
    floorOptions: {},
    roomOptions: {},
    type: {
      default: 1 //1为单品设备 2为系统设备
    }
  },
  data() {
    return {
      dialogTableVisible: false,
      floor: "全部",
      room: "全部",
      multipleSelection: []
    };
  },
  computed: {
    filterData() {
      if (!this.allDevices) return;
      let data = this.allDevices.filter((e) => e.categoryCode != 1);
      if (this.floor && this.floor != "全部") {
        data = data.filter((e) => e.floorName == this.floor);
      }
      if (this.room && this.room != "全部") {
        data = data.filter((e) => e.roomName == this.room);
      }
      return data;
    },
    subRoomOptions() {
      if (!this.roomOptions) {
        return;
      }
      let roomOptions = this.roomOptions.filter((e) => !!e.hasDevice);
      if (roomOptions.length) {
        let rooms = roomOptions;
        if (this.floor != "全部") {
          rooms = roomOptions.filter((e) => {
            return e.floor == this.floor.split("楼")[0];
          });
        }
        return rooms.map((e) => e.name);
      }
      return [];
    }
  },
  watch: {
    floor() {
      this.room = "全部";
    }
  },
  methods: {
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    saveDevice() {
      this.handleClose();
      this.$emit("saveDevice", [...this.multipleSelection]);
    },
    handleClose() {
      this.dialogTableVisible = false;
      this.floor = "全部";
      this.room = "全部";
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__body {
  padding-top: 10px;
}
.tab > span {
  display: inline-block;
  width: 40px;
}
.tab {
  min-height: 64px;
  line-height: 40px;
  & > span {
    display: inline-block;
    width: 40px;
    vertical-align: top;
    padding-top: 6px;
  }
  & > .el-radio-group {
    vertical-align: top;
    width: 460px;
  }
}
.el-radio-button {
  margin: 6px 12px;
  text-align: center;
}
::v-deep .el-radio-button__inner {
  margin-right: 0 !important;
  padding: 0;
  border: 1px solid #d8d8d8 !important;
  border-radius: 4px !important;
  min-width: 76px;
  height: 40px;
  line-height: 30px;
}
::v-deep .el-radio-button__orig-radio:checked + .el-radio-button__inner {
  color: $primary-color;
  border-color: transparent !important;
}
</style>
