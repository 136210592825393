<!--
 * @Description: 
 * @Author: zhongyuanrui
 * @LastEditors: zhongyuanrui
 * @FilePath: \homeauto-web-v3.0\src\views\building\components\ImportConfigDialog.vue
-->
<template>
  <form-dialog title="配置导入" :confirmVisible="false" @close="$emit('close')">
    <div class="import-config">
      <span>选择文件</span>
      <upload-button label="导入" :customUpload="(files) => importConfig(files)" :loading="uploadLoading" />
    </div>
    <div class="import-history">
      <div class="import-history-title">历史记录</div>
      <div class="import-history-list">
        <div class="import-history-item" v-for="(item, index) in historyList" :key="index">
          上次导入时间：{{ item.createTime }}
        </div>
      </div>
    </div>
  </form-dialog>
</template>

<script>
import { ApiHouseTemplate } from "@/api/building";

export default {
  name: "ImportConfigDialog",
  props: {
    detail: {
      type: Object,
      default: null
    },
    projectId: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      uploadLoading: false,
      historyList: []
    };
  },
  created() {
    this.initData();
  },
  methods: {
    // 初始化
    async initData() {
      if (!this.detail) return;
      const { id: templateId } = this.detail;
      try {
        const res = await ApiHouseTemplate.importHistory(templateId);
        this.historyList = res;
      } catch (error) {
        console.log(error);
      }
    },
    // 配置导入
    async importConfig(files) {
      const { id: templateId } = this.detail;
      const form = new FormData();
      files.forEach((item) => {
        form.append("file", item);
      });
      this.uploadLoading = true;
      try {
        await ApiHouseTemplate.importPoint(form, this.projectId, templateId);
        this.$message.success("上传成功");
        this.initData();
      } catch (error) {
        console.log(error);
      }
      this.uploadLoading = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.import-config {
  margin-bottom: 20px;
  span {
    margin-right: 20px;
  }
}
.import-history {
  &-title {
    font-weight: bold;
    margin-bottom: 10px;
  }
}
</style>
