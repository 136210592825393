<template>
  <div class="screen-config" v-loading="loading">
    <a-collapse v-model="activeNames" :bordered="false" v-if="list.length">
      <a-collapse-panel v-for="(item, index) in list" :key="`${index}`" :style="customStyle">
        <template v-slot:header>
          <div style="font-size: 16px">
            {{ item.templateName }}
          </div>
        </template>
        <a-collapse :bordered="false" v-if="item.products.length">
          <a-collapse-panel
            v-for="childItem in item.products"
            :key="`${childItem.productCode}`"
            :style="customStyleChild"
          >
            <template v-slot:header>
              <div style="display: flex; gap: 20px">
                <span>{{ childItem.productCode }}</span>
                <span>{{ childItem.productName }}</span>
                <span>{{ childItem.categoryName }}</span>
                <span>{{ childItem.protocol }}</span>
              </div>
            </template>
            <el-table
              :data="childItem.attrs"
              style="margin: 12px 40px; width: auto"
              header-cell-class-name="table-header-cell-style"
            >
              <el-table-column prop="attrName" label="属性名称" align="center"> </el-table-column>
              <el-table-column prop="attrCode" label="属性Code" align="center"> </el-table-column>
              <el-table-column prop="attrAddress" label="属性点位" align="center"> </el-table-column>
              <el-table-column prop="coefficient" label="系数" align="center"> </el-table-column>
              <el-table-column prop="reportFrequency" label="上报频次" align="center"> </el-table-column>
              <el-table-column prop="unsigned" label="有无符号" align="center"> </el-table-column>
              <el-table-column prop="step" label="步幅" align="center"> </el-table-column>
              <el-table-column prop="precision" label="精度" align="center"> </el-table-column>
            </el-table>
          </a-collapse-panel>
        </a-collapse>
        <el-empty v-else description="暂无数据"></el-empty>
      </a-collapse-panel>
    </a-collapse>
    <el-empty v-else description="暂无数据"></el-empty>
  </div>
</template>

<script>
import { ApiScreenConfig } from "@/api/building";
export default {
  name: "ScreenConfig",
  data() {
    return {
      columns: [
        {
          label: "属性名称",
          prop: "name"
        },
        {
          label: "属性Code",
          prop: "code"
        },
        {
          label: "属性点位",
          prop: "point"
        },
        {
          label: "系数",
          prop: "number"
        },
        {
          label: "上报频次",
          prop: "count"
        },
        {
          label: "有无符号",
          prop: "symbol"
        },
        {
          label: "步幅",
          prop: "stride"
        },
        {
          label: "精度",
          prop: "precision"
        }
      ],
      activeNames: [],
      list: [],
      loading: false,
      customStyle: "background: #fff;margin-bottom: 20px;border: 0;overflow: hidden",
      customStyleChild: "background: #fff;border: 0;overflow: hidden"
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      this.loading = true;
      const res = await ApiScreenConfig.tree(this.$route.query.projectId).finally(() => {
        this.loading = false;
      });
      this.list = res;
      console.log(res);
    }
  }
};
</script>

<style lang="scss" scoped>
.screen-config {
  height: calc(100% - 80px);
  margin: 20px 0 0;
  overflow: auto;
  ::v-deep .ant-collapse-borderless {
    background-color: #f6f6f6;
  }
  ::v-deep .ant-collapse-header {
    border-bottom: 1px solid #d9d9d9;
  }
  ::v-deep .table-header-cell-style {
    padding: 10px 0;
    background: #fafafa;
    font-size: 14px;
  }
}
</style>
