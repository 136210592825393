<template>
  <div class="detail-page-wrap scene-detail">
    <div class="detail-page-content">
      <base-card title="全屋场景配置" style="margin-top: 20px">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px">
          <el-row>
            <el-form-item label="场景名称" prop="name">
              <el-input v-model="ruleForm.name" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item label="场景图标" prop="icon">
              <el-radio-group v-model="ruleForm.icon">
                <el-radio-button :label="item.value" v-for="(item, idx) in imgsOptions" :key="idx" :border="false">
                  <el-tooltip class="item" effect="dark" :content="item.label" placement="top-start">
                    <el-image style="width: 30px; height: 30px" :src="item.value.split(',')[0]" fit="fill"></el-image>
                  </el-tooltip>
                </el-radio-button>
              </el-radio-group>
            </el-form-item>
          </el-row>
        </el-form>
      </base-card>

      <base-card class="environment" v-if="sysDevice" title="环境控制" style="margin-top: 20px">
        <p style="box-shadow: 0px 0px 4px 0px rgba(187, 187, 187, 0.3); height: 60px; padding: 15px 0 0 20px">
          <el-button size="small" type="primary" @click="addLinkage">添加联动组</el-button>
        </p>
        <environment-control
          ref="control"
          @add="addEnvItem(idx)"
          v-for="(item, idx) in linkageGroups"
          :key="idx"
          :linkageGroup="item"
          :idx="idx + 1"
          :allDevices="allDevices"
          :floorAndRoomOptions="floorAndRoomOptions"
          :hasDel="linkageGroups.length > 1"
          @delLinkage="delLinkage(idx)"
        ></environment-control>
      </base-card>

      <base-card class="device" title="设备控制" style="margin-top: 20px">
        <card-table title="触发条件" v-if="sysDevice" opBtn="添加时间条件" :showTable="false" @edit="addTimeRange">
          <ul>
            <li class="time-range" v-for="(item, idx) in timeCondition" :key="idx">
              <span>时间范围</span>
              <el-time-picker
                v-model="item.startTime"
                style="width: 140px; margin-right: 20px"
                placeholder="开始时间"
                format="HH:mm"
                value-format="HH:mm:ss"
                size="small"
              >
              </el-time-picker>
              <el-time-picker
                v-model="item.endTime"
                style="width: 140px"
                placeholder="结束时间"
                format="HH:mm"
                value-format="HH:mm:ss"
                size="small"
                :picker-options="{
                  selectableRange: `${item.startTime} - 23:59:00`
                }"
              />
              <el-button type="danger" size="small" @click="delTimeCondition(idx)">删除</el-button>
            </li>
          </ul>
        </card-table>
        <card-table title="执行动作" opBtn="新增执行设备" :showTable="false" @edit="addItem">
          <el-table class="task" :data="tableData" :header-cell-style="{ background: 'rgba(0, 0, 0, 0.02)' }" border>
            <el-table-column prop="floorAndRoom" label="楼层房间" width="180"> </el-table-column>
            <el-table-column prop="deviceName" label="设备名称" width="180"> </el-table-column>
            <el-table-column prop="address" label="状态">
              <template slot-scope="scope">
                <task-item
                  class="task-item"
                  v-for="item in scope.row.attributeVOS"
                  :key="item.id"
                  :item="item"
                  @changeCheckbox="item.selected = $event"
                ></task-item>
              </template>
            </el-table-column>
            <el-table-column fixed="right" label="操作" width="80" header-align="center">
              <template slot-scope="scope">
                <table-button @click.native.prevent="delItem(scope.$index)" type="danger"> 删除 </table-button>
              </template>
            </el-table-column>
          </el-table>
        </card-table>
      </base-card>

      <task-add-item
        ref="homeAdd"
        :floorOptions="floorOptions"
        :roomOptions="roomOptions"
        :allDevices="allDevices"
        @saveDevice="saveDevice"
      ></task-add-item>

      <environment-condition-add-item
        ref="envConAdd"
        :floorOptions="floorOptions"
        :roomOptions="roomOptions"
        :allDevices="allConditionDeviceData"
        @saveEnvDevice="saveEnvDevice"
      ></environment-condition-add-item>
      <div class="detail-page-footer">
        <common-button type="info" plain @click="$router.back()">取消</common-button>
        <common-button type="primary" @click="submit">确定</common-button>
      </div>
    </div>
  </div>
</template>

<script>
import { searchEnableTagDic } from "@/api/common";
import TaskAddItem from "../../components/SceneTaskAddItem.vue";
import { ApiRoomScene } from "@/api/building";
import TaskItem from "../../components/SceneTaskItem.vue";
import EnvironmentControl from "../../components/EnvironmentControl.vue";
import CardTable from "../../../../components/cardTable/CardTable.vue";
import { deepClone } from "@/utils/util";
import EnvironmentConditionAddItem from "../../components/EnvironmentConditionAddItem.vue";

const linkageItem = {
  conditions: [],
  sysDevice: null,
  deviceActions: []
};
export default {
  name: "SceneDetail",
  components: { TaskAddItem, TaskItem, EnvironmentControl, CardTable, EnvironmentConditionAddItem },
  props: {},
  data() {
    return {
      ruleForm: {
        name: "",
        icon: ""
      },
      rules: {
        name: [{ required: true, message: "请输入", trigger: "blur" }],
        icon: [{ required: true, message: "请选择", trigger: "change" }]
      },
      imgsOptions: null,
      floorOptions: null,
      roomOptions: null,
      allDevices: [],
      allConditionDeviceData: [],
      tableData: [],
      defaultFlag: 0,
      id: null,
      type: 1,
      timeCondition: [{ startTime: undefined, endTime: undefined }],
      sysDevice: null,
      floorAndRoomOptions: [],
      linkageGroups: [deepClone(linkageItem)],
      op: ["=", ">", "≥", "<", "≤"]
    };
  },
  mounted() {
    this.initOptions();
    this.initBreadcrumbs();
  },
  methods: {
    delLinkage(idx) {
      this.linkageGroups.splice(idx, 1);
    },
    delTimeCondition(idx) {
      this.timeCondition.splice(idx, 1);
    },
    objToArr(object) {
      let arr = [];
      for (let key in object) {
        arr.push(object[key]);
      }
      return arr;
    },
    listToTree(list) {
      let uniqueObject = {};
      list.forEach((e) => {
        if (e.hasPanel > 0) {
          if (uniqueObject[e.floor]) {
            uniqueObject[e.floor].children.push({
              label: e.name,
              value: e.name
            });
          } else {
            uniqueObject[e.floor] = {
              label: e.floor + "楼",
              value: e.floor,
              children: [
                {
                  label: e.name,
                  value: e.name
                }
              ]
            };
          }
        }
      });
      return this.objToArr(uniqueObject);
    },
    initBreadcrumbs() {
      let query = this.$route.query;
      let breadcrumbs = JSON.parse(query.breadcrumbs);
      breadcrumbs.push({
        path: this.$route.path,
        title: "配置全屋场景",
        query: { ...query }
      });
      this.$store.commit("SET_BREADCRUMBS", breadcrumbs);
    },
    initOptions() {
      searchEnableTagDic("scene_icon").then((res) => {
        this.imgsOptions = res;
      });
      ApiRoomScene.floorAndRoomOptions(this.$route.query.houseTemplateId).then((res) => {
        this.floorOptions = res.floors;
        this.roomOptions = res.rooms;
        this.floorAndRoomOptions = this.listToTree(res.rooms);
      });

      ApiRoomScene.allFloorRoomDeviceList(this.$route.query.houseTemplateId).then((res) => {
        this.allDevices = res.filter((e) => e.categoryCode != 1 && e.systemFlag == 0);

        // 过滤出系统产品
        let sysDevice = res.find((e) => e.categoryCode == 1);
        if (sysDevice) {
          this.sysDevice = this.formatSysDevice(sysDevice);
        }
        this.initData();
      });
      ApiRoomScene.allFloorRoomDeviceList2(this.$route.query.houseTemplateId).then((res) => {
        this.allConditionDeviceData = res;
      });
      // 新增条件的设备列表（和其它的不公用）
    },
    formatSysDevice(sysDevice) {
      let { id, deviceId, deviceSn, productCode, productId, attributes, attributeVOS } = sysDevice;
      attributes = deepClone(attributes || attributeVOS);
      let sysSwitch = attributes.find((e) => e.code == "system_switch");
      let sysMode = attributes.find((e) => e.code == "mode");
      let sysWindSpeed = attributes.find((e) => e.code == "wind_speed");
      return {
        deviceId: id || deviceId,
        deviceSn,
        productCode,
        productId,
        sysSwitch: {
          label: sysSwitch.name,
          value: sysSwitch.val == "on",
          options: sysSwitch.infos
        },
        sysMode: {
          label: sysMode.name,
          value: sysMode.val,
          options: sysMode.infos
        },
        sysWindSpeed: {
          label: sysWindSpeed.name,
          value: sysWindSpeed.val,
          options: sysWindSpeed.infos
        }
      };
    },
    initData() {
      if (this.$route.query.sceneId) {
        ApiRoomScene.detail(this.$route.query.sceneId).then((res) => {
          this.id = res.id;
          this.ruleForm.icon = res.icon;
          this.ruleForm.name = res.name;
          this.linkageGroups = res.envControl.map((e) => {
            // conditions的展示转换
            let conditions = e.condition?.map((con) => {
              let { deviceId, floor, roomName, productCode, productId, deviceName, deviceSn, attributeVOS } = con;
              let conditionItem = {
                id: deviceId,
                floor,
                roomName,
                productCode,
                productId,
                deviceName,
                deviceSn,
                floorAndRoom: floor + "楼" + roomName
              };
              let selectItem = attributeVOS.find((attr) => attr.selected == 1);
              conditionItem.attr = {
                label: selectItem.name,
                value: selectItem.code,
                options: attributeVOS,
                type: selectItem.type
              };
              conditionItem.attrOp = {
                label: this.op[con.operatorType],
                value: con.operatorType
              };
              conditionItem.attrValue = {
                label: selectItem.type == 1 ? selectItem.infos.find((e) => e.code == con.targetVal)?.name : null,
                value: con.targetVal,
                options: selectItem.infos,
                scopeVO: selectItem.scopeVO
              };
              return conditionItem;
            });
            // sysDevice的展示转换
            let sysDevice = e.deviceConfigs.find((e) => e.categoryCode == 1);
            let deviceActions = e.deviceConfigs.filter((e) => e.categoryCode != 1);
            deviceActions = deviceActions.map((e) => {
              let switchBtn = e.attributeVOS.find((e) => e.code == "switch");
              let tempSet = e.attributeVOS.find((e) => e.code == "temp_set");
              return {
                room: [e.floor, e.roomName],
                switchBtn: switchBtn.val == "on",
                temp_set: tempSet.val,
                tempScopeVO: tempSet.tempSet,
                device: e
              };
            });
            return {
              conditions,
              sysDevice: this.formatSysDevice(sysDevice),
              deviceActions
            };
          });

          if (res.deviceControl) {
            if (res.deviceControl.timeCondition) {
              this.timeCondition = res.deviceControl.timeCondition.map((e) => ({
                startTime: e.startTime + ":00",
                endTime: e.endTime + ":00"
              }));
            }
            if (res.deviceControl.deviceConfigs) {
              this.tableData = res.deviceControl.deviceConfigs.map((e) => {
                e.floorAndRoom = e.floorName + " - " + e.roomName;
                e.attributeVOS.forEach((attr) => {
                  attr.selected = attr.selected == 1 ? true : false;
                });
                return e;
              });
            }
          }
        });
      } else {
        this.linkageGroups.forEach((e) => (e.sysDevice = deepClone(this.sysDevice)));
      }
    },

    addTimeRange() {
      this.timeCondition.push({ startTime: undefined, endTime: undefined });
    },
    addItem() {
      this.$refs.homeAdd.dialogTableVisible = true;
    },
    delItem(idx) {
      this.tableData.splice(idx, 1);
    },
    saveDevice(items) {
      items.forEach((item) => {
        let e = this.tableData.find((e) => e.deviceSn == item.deviceSn);
        if (!e) {
          let m = {
            ...item,
            floorAndRoom: item.floorName + " - " + item.roomName
          };
          m.attributeVOS = item.attributes.map((e) => {
            e.selected = false;
            return e;
          });
          this.tableData.push(m);
        }
      });
    },
    getTableData() {
      if (this.tableData.length) {
        // 数据检测 - 是否有一个勾选
        let item = this.tableData.find((device) => {
          return device.attributeVOS.every((e) => !e.selected);
        });
        if (item) {
          this.$message.error(item.deviceName + "设备至少选择一个状态");
          return {
            pass: false
          };
        }
        // 检测数据勾选完，是否设置了值
        let checkNull = this.tableData.find((device) => {
          let item = device.attributeVOS.find((e) => {
            if (e.selected && !e.val && e.val !== 0 && e.val !== "0") {
              this.$message.error(`请${e.type == 2 ? "输入" : "选择"}${device.deviceName}${e.name}的值`);
              return true;
            }
            return false;
          });
          return !!item;
        });
        if (checkNull) {
          return {
            pass: false
          };
        }

        // 生成数据
        let m = [];
        this.tableData.forEach((device) => {
          let deviceInfo = {
            deviceSn: device.deviceSn,
            deviceId: device.id || device.deviceId,
            productId: device.productId,
            productCode: device.productCode,
            actions: []
          };
          device.attributeVOS.forEach((e) => {
            if (e.selected) {
              let action = {
                val: e.val,
                attributeCode: e.code
              };
              deviceInfo.actions.push(action);
            }
          });
          m.push(deviceInfo);
        });
        return {
          pass: true,
          deviceActions: m
        };
      }
      return {
        pass: true,
        deviceActions: []
      };
    },

    // 环境控制
    saveEnvDevice(item) {
      let conditions = this.linkageGroups[this.linkageGroupIdx].conditions;
      let oldItems = conditions.filter((e) => e.id == item.id || e.deviceId == item.id);
      // 可能存在多个
      let pass = true;
      if (oldItems?.length) {
        oldItems.forEach((e) => {
          if (e.attr.value === item.attr.value) {
            this.$message.warning("当前设备条件已设置");
            pass = false;
            return;
          }
        });
      }
      pass && conditions.push(item);
    },
    addLinkage() {
      let item = deepClone(linkageItem);
      item.sysDevice = deepClone(this.sysDevice);
      this.linkageGroups.push(item);
    },
    addEnvItem(idx) {
      this.linkageGroupIdx = idx;
      this.$refs.envConAdd.dialogTableVisible = true;
    },
    conditionsFormatToApi(conditions) {
      let pass = true;
      let existLen = [];
      conditions?.forEach((e) => {
        existLen = conditions.filter((con) => con.id == e.id && con.attr.value == e.attr.value);
      });

      if (existLen.length > 1) {
        this.$message.error("当前属性设置已存在");
        return false;
      }
      let attrPass = true;
      let cons = conditions?.map((e) => {
        if (!e.attrValue.value || (!e.attrOp.value && e.attrOp.value !== 0)) {
          pass = false;
          attrPass = false;
          return;
        }

        let deviceId = e.deviceId || e.id;

        return {
          deviceId,
          deviceSn: e.deviceSn,
          productCode: e.productCode,
          productId: e.productId,
          actions: [
            {
              attributeCode: e.attr.value,
              val: e.attrValue.value,
              operatorType: e.attrOp.value
            }
          ]
        };
      });
      if (!attrPass) {
        this.$message.error("房间的属性不能重复");
      } else if (!pass) {
        this.$message.warning(`联动组触发条件的状态不能有空值`);
      }
      return pass ? cons : false;
    },
    mergeCondition(conditions) {
      let cons = [];
      conditions?.forEach((e) => {
        let item = cons.find((con) => con.deviceId == e.id || con.deviceId == e.deviceId);
        if (item) {
          item.actions.push(e.actions[0]);
        } else {
          cons.push(e);
        }
      });
      return cons;
    },
    getLinkGroupsData() {
      let pass = true;
      let linkageGroups = this.linkageGroups.map((e) => {
        if (!pass) {
          return;
        }
        // 系统设备执行动作
        let { deviceId, deviceSn, productCode, productId, sysMode, sysSwitch, sysWindSpeed } = e.sysDevice;
        // if (!sysMode.value) {
        //   this.$message.warning("请选择执行动作的模式");
        //   pass = false;
        //   return;
        // }

        let sysDeviceActions = [
          {
            attributeCode: "system_switch",
            val: sysSwitch.value ? "on" : "off"
          }
        ];
        if (sysMode.value) {
          sysDeviceActions.push({
            attributeCode: "mode",
            val: sysMode.value
          });
        }
        if (sysWindSpeed.value) {
          sysDeviceActions.push({
            attributeCode: "wind_speed",
            val: sysWindSpeed.value
          });
        }
        let sysDevice = {
          deviceId,
          deviceSn,
          productCode,
          productId,
          actions: sysDeviceActions
        };
        let deviceActions = [sysDevice];

        // 温度设定
        let tempActions = e.deviceActions.filter((e) => !!e.device);
        let rooms = [];

        tempActions = tempActions.map((e) => {
          let room = e.room.join(",");
          if (rooms.includes(room)) {
            pass = false;
            this.$message.warning("温度设定选择的房间不能重复");
          }
          rooms.push(room);
          return {
            deviceId: e.device.id || e.device.deviceId,
            deviceSn: e.device.deviceSn,
            productCode: e.device.productCode,
            productId: e.device.productId,
            actions: [
              { attributeCode: "switch", val: e.switchBtn ? "on" : "off" },
              { attributeCode: "temp_set", val: e.temp_set }
            ]
          };
        });

        deviceActions.push(...tempActions);
        let conditions = this.conditionsFormatToApi(e.conditions);
        if (conditions === false) {
          pass = false;
          return;
        }
        return {
          condition: this.mergeCondition(conditions),
          deviceActions
        };
      });
      return pass ? linkageGroups : false;
    },
    submit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          // 判断是否为默认场景
          let { projectId, houseTemplateId } = this.$route.query;
          let m = {
            ...this.ruleForm,
            type: 1, //1.全屋场景
            projectId,
            houseTemplateId,
            defaultFlag: 0,
            updateFlag: 0
          };
          if (this.id) {
            m.id = this.id;
          }

          this.$nextTick(() => {
            if (this.sysDevice) {
              let envControl = this.getLinkGroupsData();
              if (envControl === false) {
                // this.$message.warning("请配置环境条件");
                return;
              }
              m.envControl = envControl;
            }

            let deviceActions = this.getTableData();
            if (!deviceActions.pass) {
              return;
            }

            m.deviceControl = {
              deviceActions: deviceActions.deviceActions
            };
            if (this.sysDevice) {
              let timeCondition = this.timeCondition.filter((e) => e.startTime && e.endTime);
              m.deviceControl.timeCondition = timeCondition.map((e) => ({
                startTime: e.startTime?.slice(0, 5),
                endTime: e.endTime?.slice(0, 5)
              }));
            }

            let func = m.id ? "update" : "add";
            ApiRoomScene[func](m).then(() => {
              this.$message.success(`${m.id ? "编辑" : "新增"}成功`);
              this.$router.back();
            });
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.scene-detail {
  padding-bottom: 60px;
}
.el-input {
  width: 232px;
}
// 场景图标
::v-deep .el-radio-button__inner {
  margin-right: 10px;
  padding: 5px !important;
  border: 1px solid transparent;
}
::v-deep .el-radio-button:first-child .el-radio-button__inner {
  border-left-color: transparent;
}
::v-deep .el-radio-button__orig-radio:checked + .el-radio-button__inner {
  border: 1px solid #00c4b3;
  background: rgba(0, 196, 179, 0.1);
  box-shadow: none;
  border-radius: 4px;
}
::v-deep .task tr td:nth-child(3) {
  padding: 0 !important;
  .cell {
    padding: 0 !important;
  }
}

.el-table__row .task-item:last-child {
  border-bottom: none !important;
}

.environment,
.device {
  .el-card {
    margin: 0;
    box-shadow: none;
    border-bottom: none;
    border-radius: 0;
  }
  ::v-deep .el-card__body {
    padding: 0;
  }
}

.time-range {
  padding-top: 15px;
  height: 60px;
  span {
    display: inline-block;
    width: 100px;
    text-align: center;
  }
  .el-button {
    margin-left: 20px;
  }
}
</style>
