<!--
 * @Description: 批量新增楼房 - 楼层填写
 * @Author: zhongyuanrui
 * @LastEditors: zhongyuanrui
 * @FilePath: \homeauto-web-v3.0\src\views\building\projectDetail\buildingSetting\AddBatchFloorForm.vue
-->
<template>
  <el-form
    class="add-batch-floor-form"
    ref="form"
    label-width="90px"
    label-position="left"
    :model="data"
    :rules="formRule"
  >
    <el-form-item label="楼栋" prop="buildingCode">
      <el-input v-model.number="data.buildingCode" placeholder="请填写楼栋号"></el-input>
    </el-form-item>
    <el-form-item label="单元数" prop="unitsNum">
      <el-input v-model.number="data.unitsNum" placeholder="请填写单元数" @change="changeUnitsNum"></el-input>
    </el-form-item>
    <el-form-item label="楼层" prop="floorStart">
      <el-form-item prop="floorStart" class="form-item">
        <el-input class="short-input" v-model.number="data.floorStart" placeholder="开始楼层"></el-input>
      </el-form-item>
      <span class="short-input-line">-</span>
      <el-form-item prop="floorEnd" class="form-item">
        <el-input class="short-input" v-model.number="data.floorEnd" placeholder="结束楼层"></el-input>
      </el-form-item>
    </el-form-item>
    <el-form-item label="跳过楼层" prop="skipFloor">
      <el-input
        v-for="(item, index) in data.skipFloor"
        :key="index"
        class="short-input skip-floor-input"
        :value="item"
        placeholder="请填写楼层"
        @input="(value) => inputSkipFloor(value, index)"
      >
        <i slot="suffix" class="el-icon-error" @click="removeSkipFloor(index)"></i>
      </el-input>
      <el-button type="primary" icon="el-icon-circle-plus-outline" @click="addSkipFloor">添加</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import { deepClone } from "@/utils/util";

export default {
  name: "AddBatchFloorForm",
  model: {
    prop: "data",
    event: "change"
  },
  props: {
    data: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      formRule: {
        buildingCode: [
          { required: true, message: "请填写楼栋号", trigger: "blur" },
          { type: "number", message: "楼栋号必须为数字值" }
        ],
        unitsNum: [
          { required: true, message: "请填写单元数", trigger: "blur" },
          { type: "number", message: "单元数必须为数字值" }
        ],
        floorStart: [
          { required: true, message: "请填写开始楼层", trigger: "blur" },
          { type: "number", message: "楼层必须为数字值" }
        ],
        floorEnd: [
          { required: true, message: "请填写结束楼层", trigger: "blur" },
          { type: "number", message: "楼层必须为数字值" }
        ]
      }
    };
  },
  methods: {
    inputSkipFloor(value, index) {
      let _data = deepClone(this.data);
      _data.skipFloor[index] = value;
      this.$emit("change", _data);
    },
    // 添加跳过楼层
    addSkipFloor() {
      let _data = deepClone(this.data);
      _data.skipFloor.push("");
      this.$emit("change", _data);
    },
    // 删除跳过楼层
    removeSkipFloor(index) {
      let _data = deepClone(this.data);
      _data.skipFloor.splice(index, 1);
      this.$emit("change", _data);
    },
    changeUnitsNum(value) {
      this.$emit("change-units-num", value);
    }
  }
};
</script>

<style lang="scss" scoped>
.add-batch-floor-form {
  .form-item {
    display: inline-block;
  }
  .skip-floor-input {
    margin-right: 32px;
    margin-bottom: 24px;
  }
  .el-icon-error {
    margin-right: 4px;
    color: rgba(0, 0, 0, 0.15);
    cursor: pointer;
    &:hover {
      color: rgba(0, 0, 0, 0.2);
    }
  }
}
</style>
