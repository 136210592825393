<template>
  <div>
    <room-list :breadcrumbs="breadcrumbs" />
    <scene-list :breadcrumbs="breadcrumbs" />
  </div>
</template>

<script>
import RoomList from "./room/RoomList.vue";
import SceneList from "./scene/SceneList.vue";
export default {
  name: "HouseTemplateSetting",
  components: { SceneList, RoomList },
  mounted() {
    this.initBreadcrumbs();
  },
  data() {
    return {
      breadcrumbs: []
    };
  },
  methods: {
    initBreadcrumbs() {
      let query = this.$route.query;
      let breadcrumbs = JSON.parse(query.breadcrumbs);
      breadcrumbs.push({
        path: this.$route.path,
        title: this.$route.query.houseTemplateName,
        query: { ...this.$route.query }
      });
      this.breadcrumbs = breadcrumbs;
      this.$store.commit("SET_BREADCRUMBS", breadcrumbs);
    }
  }
};
</script>
