<template>
  <div>
    <base-list-page
      :searchModel="searchModel"
      :tableModel="tableModel"
      :paginationModel.sync="paginationModel"
      @change="refreshTableData"
      addBtn="修改户型"
      :hideBtnIcon="true"
      @addItem="changeHouseTemplate"
      @selection-change="selectionChange"
    >
      <template v-slot:button>
        <el-button class="del-btn" type="danger" size="small" @click="delItems"> 删除</el-button>
      </template>
    </base-list-page>
    <el-dialog
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      title="修改户型"
      :visible.sync="visible"
      width="540px"
      :before-close="handleClose"
    >
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px">
        <el-form-item label="请选择户型" prop="houseTemplateId">
          <el-select v-model="ruleForm.houseTemplateId" placeholder="请选择" clearable>
            <el-option
              v-for="item in houseTemplateOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose" size="small">取 消</el-button>
        <el-button type="primary" @click="submit" size="small">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { basePaginationProperty } from "@/components/basePagination/basePaginationProperty";
import { searchFormProperty, searchFormItemProperty } from "@/components/form/searchFormProperty";
import { VariableType } from "@/components/baseInput/baseInputProperty";
import { baseTableProperty, colProperty } from "@/components/baseTable/baseTableProperty";
import { ApiBuildingSettingDetail, ApiBuildingSetting } from "@/api/building";
export default {
  name: "BuildingSettingDetail",
  components: {},
  props: {},
  data() {
    return {
      searchModel: null,
      tableModel: null,
      paginationModel: new basePaginationProperty(),
      multipleSelection: [],
      visible: false,
      ruleForm: {
        houseTemplateId: ""
      },
      rules: {
        houseTemplateId: [{ required: true, message: "请选择", trigger: "change" }]
      },
      houseTemplateOptions: null,
      breadcrumbs: [],
      projectId: Number(this.$route.query.projectId)
    };
  },
  computed: {},
  mounted() {
    this.initSearch();
    this.initTableList();
    this.initBreadcrumbs();
    this.initOptions();
  },
  methods: {
    initBreadcrumbs() {
      let query = this.$route.query;
      let breadcrumbs = JSON.parse(query.breadcrumbs);
      breadcrumbs.push({
        path: this.$route.path,
        title: this.$route.query.buildingCode + "栋",
        query: { ...this.$route.query }
      });
      this.breadcrumbs = breadcrumbs;
      this.$store.commit("SET_BREADCRUMBS", breadcrumbs);
    },
    initOptions() {
      ApiBuildingSetting.getTemplateList(this.projectId).then((res) => {
        this.houseTemplateOptions = res;
      });
      let m = {
        projectId: this.projectId,
        buildCode: this.$route.query.buildingCode
      };
      ApiBuildingSettingDetail.floorOptions(m).then((res) => {
        this.searchModel.setOptions("floor", res);
      });
      ApiBuildingSettingDetail.unitOptions(m).then((res) => {
        this.searchModel.setOptions("unitCode", res);
      });
    },
    initSearch() {
      let searchModel = searchFormProperty.Init();
      let items = [
        searchFormItemProperty.Init("所在单元", "unitCode", VariableType.SELECT),
        searchFormItemProperty.Init("所在楼层", "floor", VariableType.SELECT),
        searchFormItemProperty.Init("门牌号", "doorplate"),
        searchFormItemProperty.Init("房屋编号", "code")
      ];
      searchModel.setItems(items);
      searchModel.setClearable(["unitCode", "floor"]);
      searchModel.showReset = true;
      this.searchModel = searchModel;
    },
    initTableList() {
      let model = new baseTableProperty();
      model.colProperty = [
        colProperty.Init("房屋编号", "code", "200px"),
        colProperty.Init("房屋名称", "name", "200px"),
        colProperty.Init("所在楼栋", "buildingCodeStr"),
        colProperty.Init("所在单元", "unitCodeStr"),
        colProperty.Init("所属楼层", "floorName"),
        colProperty.Init("门牌号", "doorplate"),
        colProperty.Init("户型", "templateName")
      ];
      model.selection = true;
      model.opBtnsWidth = "120px";
      this.tableModel = model;
      this.refreshTableData();
    },
    refreshTableData() {
      let m = {
        pageNum: this.paginationModel.currentPage,
        pageSize: this.paginationModel.pageSize,
        projectId: this.projectId,
        buildingCode: this.$route.query.buildingCode,
        ...this.searchModel.getItemsValue()
      };
      ApiBuildingSettingDetail.list(m).then((res) => {
        this.tableModel.setData(res.list);
        this.paginationModel.total = res.total;
      });
    },
    changeHouseTemplate() {
      if (this.multipleSelection.length) {
        this.visible = true;
      } else {
        this.$message.warning("请先选择需要修改的房屋");
      }
    },
    handleClose() {
      this.visible = false;
      this.$refs.ruleForm && this.$refs.ruleForm.resetFields();
    },
    submit() {
      let m = {
        familyIds: this.multipleSelection,
        templateId: this.ruleForm.houseTemplateId
      };
      ApiBuildingSettingDetail.changeHouseTempalte(m).then(() => {
        this.$message.success("修改成功");
        this.refreshTableData();
        this.handleClose();
      });
    },
    selectionChange(vals) {
      this.multipleSelection = vals.map((val) => val.id);
    },
    delItems() {
      if (this.multipleSelection.length === 0) {
        this.$message.warning("请先选择需要修改的房屋");
      } else {
        this.$delItem(() => {
          let m = {
            ids: this.multipleSelection,
            projectId: this.projectId
          };
          ApiBuildingSettingDetail.del(m).then(() => {
            this.$message.success("删除成功");
            this.refreshTableData();
          });
        }, "此操作将批量删除选中的家庭,是否继续?");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.del-btn {
  display: inline-block;
  width: 80px;
}
</style>
