<template>
  <div class="oplogging">
    <base-list-page :tableModel="tableModel" :paginationModel.sync="paginationModel" @change="refreshTableData">
    </base-list-page>
  </div>
</template>

<script>
import { basePaginationProperty } from "@/components/basePagination/basePaginationProperty";
import { baseTableProperty, colProperty } from "@/components/baseTable/baseTableProperty";
import { ApiOpLogging } from "@/api/building";
export default {
  name: "OpLogging",
  data() {
    return {
      tableModel: null,
      paginationModel: new basePaginationProperty()
    };
  },
  mounted() {
    this.initTableList();
  },
  methods: {
    initTableList() {
      let model = new baseTableProperty();
      model.treeProps = { children: "children", hasChildren: "hasChildren" };
      model.colProperty = [
        colProperty.Init("操作账号", "account", "250px"),
        colProperty.Init("IP地址", "ip", "200px"),
        colProperty.Init("操作时间", "createTime", "200px"),
        colProperty.Init("操作内容", "name")
      ];
      this.tableModel = model;
      this.refreshTableData();
    },
    refreshTableData() {
      let m = {
        pageNum: this.paginationModel.currentPage,
        pageSize: this.paginationModel.pageSize,
        projectId: Number(this.$route.query.projectId)
      };
      ApiOpLogging.list(m).then((res) => {
        this.tableModel.setData(res.list);
        this.paginationModel.total = res.total;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.oplogging {
  height: calc(100vh - 60px - 50px - 90px);
}
</style>
