<!--
 * @Description: 批量新增楼房 - 户号填写
 * @Author: zhongyuanrui
 * @LastEditors: zhongyuanrui
 * @FilePath: \homeauto-web-v3.0\src\views\building\projectDetail\buildingSetting\AddBatchRoomNoForm.vue
-->
<template>
  <el-form class="add-batch-room-no-form" ref="form" label-width="90px" label-position="left" :model="formData">
    <div v-for="(unit, index) in data.units" :key="index">
      <div class="unit-name">单元{{ index + 1 }}</div>
      <el-form-item v-for="(roomItem, roomIndex) in unit.rooms" :key="roomIndex" label="房号" required>
        <el-form-item class="form-item" :prop="`roomNo_${index}_${roomIndex}`" :rules="roomNoRule">
          <el-input
            v-model.number="formData[`roomNo_${index}_${roomIndex}`]"
            placeholder="请填写房号"
            @change="changeData"
          ></el-input>
        </el-form-item>
        <el-form-item class="form-item" :prop="`templateId_${index}_${roomIndex}`" :rules="templateIdRule">
          <el-select
            v-model="formData[`templateId_${index}_${roomIndex}`]"
            placeholder="请选择户型"
            @change="changeData"
          >
            <el-option
              v-for="selectItem in templateOptions"
              :key="selectItem.id"
              :label="selectItem.name"
              :value="selectItem.id"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form-item>
    </div>
    <el-form-item label="前/后缀">
      <el-input class="short-input" v-model="data.prefix" placeholder="前缀"></el-input>
      <span class="short-input-line">-</span>
      <el-input class="short-input" v-model="data.suffix" placeholder="后缀"></el-input>
    </el-form-item>
  </el-form>
</template>

<script>
import { ApiBuildingSetting } from "@/api/building";
import { deepClone } from "@/utils/util";

export default {
  name: "AddBatchRoomNoForm",
  model: {
    prop: "data",
    event: "change"
  },
  props: {
    data: {
      type: Object,
      default: null
    },
    projectId: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      formData: {},
      roomNoRule: [
        { required: true, message: "请填写房号", trigger: "blur" },
        { type: "number", message: "房号必须为数字值" }
      ],
      templateIdRule: [{ required: true, message: "请选择户型", trigger: "change" }],
      templateOptions: []
    };
  },
  created() {
    this.initData();
    this.initTemplateOptions();
  },
  methods: {
    initTemplateOptions() {
      if (!this.projectId) return;
      ApiBuildingSetting.getTemplateList(this.projectId).then((res) => {
        this.templateOptions = res;
      });
    },
    initData() {
      this.data.units.forEach((item, index) => {
        item.rooms.forEach((room, roomIndex) => {
          this.$set(this.formData, `roomNo_${index}_${roomIndex}`, room.roomNo);
          this.$set(this.formData, `templateId_${index}_${roomIndex}`, room.templateId);
        });
      });
    },
    changeData() {
      let _data = deepClone(this.data);
      for (const key in this.formData) {
        let keyList = key.split("_");
        _data.units[keyList[1]].rooms[keyList[2]][keyList[0]] = this.formData[key];
      }
      this.$emit("change", _data);
    }
  }
};
</script>

<style lang="scss" scoped>
.add-batch-room-no-form {
  .unit-name {
    margin: 0 10px 5px;
  }
  .form-item {
    display: inline-block;
    ::v-deep .el-input {
      width: 120px;
      margin-right: 10px;
    }
  }
}
</style>
