<template>
  <div class="group">
    <p class="title flex-between">
      <span>联动组{{ idx }}</span>
      <el-button type="danger" v-if="hasDel" plain size="small" style="margin-right: 20px" @click="delLinkage"
        >删除</el-button
      >
    </p>
    <card-table title="触发条件" opBtn="新增条件" :showTable="false" @edit="addItem" style="margin: 20px 20px 0">
      <el-table :data="linkageGroup.conditions" :header-cell-style="{ background: 'rgba(0, 0, 0, 0.02)' }">
        <el-table-column prop="floorAndRoom" label="楼层房间" width="180"> </el-table-column>
        <el-table-column prop="deviceName" label="设备名称" width="180"> </el-table-column>
        <el-table-column label="状态">
          <template slot-scope="scope">
            <environment-item :row="scope.row" />
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="80" header-align="center">
          <template slot-scope="scope">
            <table-button @click="delItem(scope.$index)" type="danger">删除</table-button>
          </template>
        </el-table-column>
      </el-table>
    </card-table>

    <card-table
      title="执行动作"
      :showTable="false"
      style="margin: 0 20px"
      v-if="linkageGroup.sysDevice && linkageGroup.sysDevice.sysMode"
    >
      <el-form :inline="true" :model="linkageGroup.sysDevice.sysMode" class="flex-between" style="padding: 15px 20px 0">
        <el-form-item label="模式选择">
          <el-select v-model="linkageGroup.sysDevice.sysMode.value" placeholder="请选择模式">
            <el-option
              :label="item.name"
              :value="item.code"
              v-for="item in linkageGroup.sysDevice.sysMode.options"
              :key="item.code"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="系统开关">
          <el-switch v-model="linkageGroup.sysDevice.sysSwitch.value"></el-switch>
        </el-form-item>
        <el-form-item label="新风风量">
          <el-select v-model="linkageGroup.sysDevice.sysWindSpeed.value" placeholder="请选择新风风量">
            <el-option
              :label="item.name"
              :value="item.code"
              v-for="item in linkageGroup.sysDevice.sysWindSpeed.options"
              :key="item.code"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </card-table>
    <card-table title="温度设定" opBtn="添加房间" @edit="addRoom" :showTable="false" style="margin: 0 20px">
      <el-table :data="linkageGroup.deviceActions" :header-cell-style="{ background: '#fff' }">
        <el-table-column label="选择房间" prop="room">
          <template slot-scope="scope">
            <el-cascader
              v-model="scope.row.room"
              :options="floorAndRoomOptions"
              @change="changeRoom($event, scope.row)"
            />
          </template>
        </el-table-column>
        <el-table-column label="开关设置" prop="switchBtn">
          <template slot-scope="scope">
            <el-switch v-model="scope.row.switchBtn"></el-switch>
          </template>
        </el-table-column>
        <el-table-column label="温度设定" prop="temp_set">
          <template slot-scope="scope">
            <el-input-number
              v-if="!scope.row.tempScopeVO"
              v-model="scope.row.temp_set"
              :min="0"
              :max="40"
              :precision="0"
              :step="1"
            >
            </el-input-number>
            <el-input-number
              v-else
              v-model="scope.row.temp_set"
              :min="parseFloat(scope.row.tempScopeVO.min)"
              :max="parseFloat(scope.row.tempScopeVO.max)"
              :precision="parseFloat(scope.row.tempScopeVO.precision)"
              :step="parseFloat(scope.row.tempScopeVO.step)"
            ></el-input-number>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="180" header-align="center">
          <template slot-scope="scope">
            <table-button @click="delRoom(scope.$index)" type="danger">删除</table-button>
          </template>
        </el-table-column>
      </el-table>
    </card-table>
  </div>
</template>

<script>
import CardTable from "../../../components/cardTable/CardTable.vue";
import EnvironmentItem from "./EnvironmentItem.vue";

// const op = ["=", ">", "≥", "<", "≤"];
export default {
  props: {
    idx: {
      type: Number,
      require: true
    },
    floorAndRoomOptions: {},
    allDevices: {}, //所有系统设备列表
    hasDel: {},
    linkageGroup: {}
  },
  components: { CardTable, EnvironmentItem },
  data() {
    return {
      roomTableData: [],
      deviceActions: [],
      form: {
        mode: undefined,
        windSpeed: undefined,
        switchBtn: "on"
      },
      mode: {},
      windSpeed: {},
      switchBtn: {}
    };
  },
  methods: {
    delLinkage() {
      this.$emit("delLinkage");
    },
    addItem() {
      this.$emit("add");
    },
    delItem(idx) {
      this.linkageGroup.conditions.splice(idx, 1);
    },
    delRoom(idx) {
      this.linkageGroup.deviceActions.splice(idx, 1);
    },
    addRoom() {
      this.linkageGroup.deviceActions.push({
        room: [],
        switchBtn: "on",
        temp_set: null,
        tempScopeVO: null,
        device: null
      });
    },
    changeRoom(floorAndRoom, row) {
      if (!floorAndRoom?.length) {
        return;
      }

      let device = this.allDevices.find(
        (e) =>
          ["12", "14", "26"].includes(e.categoryCode) && e.floor == floorAndRoom[0] && e.roomName == floorAndRoom[1]
      );
      row.device = device;
      let tempAttr = device.attributes.find((e) => e.code == "temp_set");
      row.tempScopeVO = tempAttr.scopeVO;
    }
  }
};
</script>

<style lang="scss" scoped>
.el-card {
  border-bottom: none;
  border-radius: 0;
}
.el-card.is-always-shadow {
  box-shadow: none;
}
.group {
  padding-bottom: 20px;
  .title {
    padding-top: 15px;
    padding-left: 40px;
    font-size: 14px;
    font-weight: 500;
    color: #333333;
    line-height: 20px;
  }
}
</style>
