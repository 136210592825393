/*
 * @Description:
 * @Author: zhongyuanrui
 * @LastEditors: zhongyuanrui
 * @FilePath: \homeauto-web-v3.0\src\api\product.js
 */
import request from "@/plugins/axios";

export const ApiProduct = {
  list(params) {
    return request({
      url: "/homeauto-center-device/device/web/product/page",
      method: "get",
      params
    });
  },
  addOrUpdate(data) {
    return request({
      url: "/homeauto-center-device/device/web/product/addOrUpdate",
      method: "post",
      data
    });
  },
  del(id) {
    return request({
      url: `/homeauto-center-device/device/web/product/delete/${id}`,
      method: "delete"
    });
  },
  detail(id) {
    return request({
      url: `/homeauto-center-device/device/web/product/detail/${id}`,
      method: "get"
    });
  },
  protocolOptions() {
    return request({
      url: "/homeauto-center-device/device/web/product/get/protocols",
      method: "get"
    });
  },
  categoryOptions() {
    return request({
      url: "/homeauto-center-device/device/web/category/get/categorys",
      method: "get"
    });
  },
  // 属性性质下拉列表
  propertyOptions() {
    return request({
      url: "/homeauto-center-device/device/web/attribute-dic/get/natures",
      method: "get"
    });
  },
  categoryDetailOptions(categoryId) {
    return request({
      url: `/homeauto-center-device/device/web/category/get/categorys/attrinfos/${categoryId}`,
      method: "get"
    });
  }
};

export const ApiSystemProduct = {
  list(params) {
    return request({
      url: "/homeauto-center-device/device/web/sys-produt/page",
      method: "get",
      params
    });
  },
  add(data) {
    return request({
      url: "/homeauto-center-device/device/web/sys-produt/add",
      method: "post",
      data
    });
  },
  edit(data) {
    return request({
      url: "/homeauto-center-device/device/web/sys-produt/update",
      method: "post",
      data
    });
  },
  del(sysProductId) {
    return request({
      url: `/homeauto-center-device/device/web/sys-produt/delete/${sysProductId}`,
      method: "delete"
    });
  },
  switchStatus(data) {
    return request({
      url: "/homeauto-center-device/device/web/sys-produt/enable/switch",
      method: "post",
      data
    });
  },
  detail(id) {
    return request({
      url: `/homeauto-center-device/device/web/sys-produt/detail/${id}`,
      method: "get"
    });
  },
  categoryOptions() {
    return request({
      url: "/homeauto-center-device/device/web/category/get/list/category-base-info",
      method: "get"
    });
  }
};

export const ApiProductCategory = {
  list(params) {
    return request({
      url: "/homeauto-center-device/device/web/category/page",
      method: "post",
      data: params
    });
  },
  addOrUpdate(data) {
    return request({
      url: "/homeauto-center-device/device/web/category/addOrUpdate",
      method: "post",
      data
    });
  },
  del(id) {
    return request({
      url: `/homeauto-center-device/device/web/category/delete/${id}`,
      method: "post"
    });
  },
  detail(categoryId) {
    return request({
      url: `/homeauto-center-device/device/web/category/get/category/attrs/${categoryId}`,
      method: "get"
    });
  },
  categoryOptions() {
    return request({
      url: "/homeauto-center-device/device/web/category/get/category-type",
      method: "get"
    });
  },
  categoryOptionsAdd() {
    return request({
      url: "/homeauto-center-device/device/web/category/get/category-type/filter",
      method: "get"
    });
  },
  attrsOptions() {
    return request({
      url: "/homeauto-center-device/device/web/attribute-dic/get/attributes-list",
      method: "get"
    });
  },
  switchStatus(id) {
    return request({
      url: `/homeauto-center-device/device/web/category/switch-status/${id}`,
      method: "post"
    });
  }
};

export const ApiProductError = {
  add(data) {
    return request({
      url: "/homeauto-center-device/device/web/product/error/add",
      method: "post",
      data
    });
  },
  update(data) {
    return request({
      url: "/homeauto-center-device/device/web/product/error/update",
      method: "post",
      data
    });
  },
  del(id) {
    return request({
      url: `/homeauto-center-device/device/web/product/delete/error/${id}`,
      method: "post"
    });
  },
  list(productId) {
    return request({
      url: `/homeauto-center-device/device/web/product/error/list/${productId}`,
      method: "get"
    });
  },
  errorTypeOptions() {
    return request({
      url: `/homeauto-center-device/device/web/product/get/errorTypes`,
      method: "get"
    });
  },
  errorCodeReadonlyOptions(productId) {
    return request({
      url: `/homeauto-center-device/device/web/product/get/list/attrs/filter/${productId}`,
      method: "get"
    });
  }
};
