<template>
  <form-dialog
    title="新增楼房"
    :formItems="formItems"
    :formData="formData"
    :formRule="formRule"
    :confirmLoading="confirmLoading"
    @close="$emit('close')"
    @confirm="confirm"
  >
    <template v-slot:prefix>
      <el-input class="short-input" v-model="formData.prefix" placeholder="前缀"></el-input>
      <span class="short-input-line">-</span>
      <el-input class="short-input" v-model="formData.suffix" placeholder="后缀"></el-input>
    </template>
  </form-dialog>
</template>

<script>
import { ApiBuildingSetting } from "@/api/building";

export default {
  name: "AddDialog",
  props: {
    projectId: {
      type: String,
      default: ""
    },
    realestateId: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      formItems: [
        {
          label: "楼栋号",
          prop: "buildingCode",
          type: "number"
        },
        {
          label: "单元号",
          prop: "unitCode",
          type: "number"
        },
        {
          label: "楼层",
          prop: "floor",
          type: "number"
        },
        {
          label: "房号",
          prop: "roomNo",
          type: "number"
        },
        {
          label: "前/后缀",
          prop: "prefix",
          slot: true
        },
        {
          label: "绑定户型",
          prop: "templateId",
          type: "select",
          options: [],
          optionsLabel: "name",
          optionsValue: "id"
        }
      ],
      formData: {
        buildingCode: "",
        unitCode: "",
        floor: "",
        roomNo: "",
        prefix: "",
        suffix: "",
        templateId: ""
      },
      formRule: {
        buildingCode: [
          { required: true, message: "请填写楼栋号", trigger: "blur" },
          { type: "number", message: "楼栋号必须为数字值" }
        ],
        unitCode: [
          { required: true, message: "请填写单元号", trigger: "blur" },
          { type: "number", message: "单元号必须为数字值" }
        ],
        floor: [
          { required: true, message: "请填写楼层", trigger: "blur" },
          { type: "number", message: "楼层必须为数字值" }
        ],
        roomNo: [
          { required: true, message: "请填写房号", trigger: "blur" },
          { type: "number", message: "房号必须为数字值" }
        ],
        templateId: [{ required: true, message: "请选择绑定户型", trigger: "change" }]
      },
      confirmLoading: false
    };
  },
  mounted() {
    this.initTemplateOptions();
  },
  methods: {
    // 初始化户型列表
    initTemplateOptions() {
      if (!this.projectId) return;
      ApiBuildingSetting.getTemplateList(this.projectId).then((res) => {
        this.$set(this.formItems[5], "options", res);
      });
    },
    async confirm() {
      let projectId = Number(this.projectId);
      let realestateId = Number(this.realestateId);
      if (!projectId || !realestateId) return;
      const params = {
        ...this.formData,
        projectId,
        realestateId
      };
      this.confirmLoading = true;
      try {
        await ApiBuildingSetting.add(params);
        this.$message.success("添加成功");
        this.$emit("add-success");
      } catch (error) {
        console.log(error);
      }
      this.confirmLoading = false;
    }
  }
};
</script>

<style lang="scss" scoped></style>
