<template>
  <div>
    <base-list-page
      :tableModel="tableModel"
      @change="refreshTableData"
      addBtn="新增楼房"
      @addItem="addItem"
      :hideBtnIcon="true"
    >
      <template v-slot:button>
        <el-button class="btn" type="primary" size="small" @click="batchAdd">批量新增楼房</el-button>
        <upload-button
          class="btn"
          label="批量导入大屏/网关地址"
          :customUpload="importAddress"
          :loading="uploadLoading"
        />
        <el-button class="btn" type="primary" size="small" @click="downloadTemplate" :loading="downloadLoading">
          模板下载
        </el-button>
        <el-button class="btn" type="primary" size="small" @click="onUpdateDoorplate">楼房号更新</el-button>
      </template>
      <el-table-column slot="fixedButtons" fixed="right" label="操作" width="180px" align="center">
        <template slot-scope="scope">
          <table-button type="primary" @click="edit(scope.row)">编辑</table-button>
          <table-button type="danger" @click="remove(scope.row)">删除</table-button>
        </template>
      </el-table-column>
    </base-list-page>
    <add-dialog
      v-if="addDialogVisible"
      :projectId="projectId"
      :realestateId="realestateId"
      @close="addDialogVisible = false"
      @add-success="addSuccess"
    />
    <add-batch-dialog
      v-if="addBatchDialogVisible"
      :projectId="projectId"
      :realestateId="realestateId"
      @close="addBatchDialogVisible = false"
      @add-success="addSuccess"
    />
    <UpdateDoorplateDialog
      v-if="updateDoorplateDialog"
      :projectId="projectId"
      @close="updateDoorplateDialog = false"
      @success="updateDoorplateDialogSuccess"
    />
  </div>
</template>

<script>
import { baseTableProperty, colProperty } from "@/components/baseTable/baseTableProperty";
import { ApiBuildingSetting } from "@/api/building";
import AddDialog from "./buildingSetting/AddDialog";
import AddBatchDialog from "./buildingSetting/AddBatchDialog";
import UpdateDoorplateDialog from "./buildingSetting/UpdateDoorplateDialog";
import { downLoad } from "@/utils/util";

export default {
  name: "BuildingSetting",
  components: {
    AddDialog,
    AddBatchDialog,
    UpdateDoorplateDialog
  },
  props: {
    breadcrumbs: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      ...this.$route.query,
      tableModel: null,
      addDialogVisible: false,
      addBatchDialogVisible: false,
      uploadLoading: false,
      downloadLoading: false,
      updateDoorplateDialog: false
    };
  },
  mounted() {
    this.initTableList();
  },
  methods: {
    initTableList() {
      let model = new baseTableProperty();
      model.colProperty = [
        colProperty.Init("楼栋号", "buildingCode"),
        colProperty.Init("单元数", "unitNum"),
        colProperty.Init("楼层数", "floorNum"),
        colProperty.Init("房屋数量（户数）", "familyNum"),
        colProperty.Init("户型数量", "templateNum")
      ];
      this.tableModel = model;
      this.refreshTableData();
    },
    refreshTableData() {
      ApiBuildingSetting.list(this.projectId).then((res) => {
        this.tableModel.setData(res);
      });
    },
    // 新增
    addItem() {
      this.addDialogVisible = true;
    },
    // 批量新增
    batchAdd() {
      this.addBatchDialogVisible = true;
    },
    // 新增成功
    addSuccess() {
      this.addDialogVisible = false;
      this.addBatchDialogVisible = false;
      this.refreshTableData();
    },
    edit({ buildingCode }) {
      this.$router.push({
        path: "/building/projectList/projectDetail/buildingSettingDetail",
        query: {
          buildingCode,
          ...this.$route.query,
          breadcrumbs: JSON.stringify(this.breadcrumbs)
        }
      });
    },
    remove({ buildingCode }) {
      this.$delItem(() => {
        const params = {
          buildingCode,
          projectId: this.projectId
        };
        ApiBuildingSetting.remove(params).then(() => {
          this.$message.success("删除成功");
          this.refreshTableData();
        });
      });
    },
    // 批量导入大屏/网关地址
    async importAddress(files) {
      const form = new FormData();
      files.forEach((item) => {
        form.append("file", item);
      });
      this.uploadLoading = true;
      await ApiBuildingSetting.importIpMac(form, this.projectId).finally(() => {
        this.uploadLoading = false;
      });
      this.$message.success("上传成功");
    },
    // 下载模板
    async downloadTemplate() {
      this.downloadLoading = true;
      const { filename, blob } = await ApiBuildingSetting.downloadTemplate(this.projectId).finally(() => {
        this.downloadLoading = false;
      });
      downLoad(blob, decodeURI(filename));
    },
    onUpdateDoorplate() {
      this.updateDoorplateDialog = true;
    },
    updateDoorplateDialogSuccess() {
      this.updateDoorplateDialog = false;
      this.refreshTableData();
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .btn {
  margin-left: 24px;
  margin-top: 10px;
}
</style>
